/* round style*/
.no-bg, .no-bg:hover, .no-bg:focus{background:none;}
.radius-bottom-16{border-radius: 0 0 16px 16px;}
.content-block{border-radius: 16px;}
.no-bg{background: none;}
.blu-bg{background: #2874F0;}
.bottom-shadow-1{box-shadow: 0 3px 6px rgb(0, 0, 0, 0.16);}
.left-navbar.fixed.round-style{ top: 80px;     width: 232px;  left: 16px;     bottom: 6px; height: auto;} 
.radius-16{border-radius:16px;}
.left-navbar.round-style ul.left-navigation li a:hover, .left-navbar.round-style ul.left-navigation li a.active { background: rgba(0,0,0,0.12); color: #fff; box-shadow:none; }
.left-navbar.round-style ul.left-navigation li a{    padding: 0 16px;}
.left-navbar.fixed.round-style .easter-eggs{font-size: 11px;}
.left-navbar.round-style .vertical-block{height: 100%;}
.radius-20{border-radius: 20px;}
.model-srch.reset .select-dropdown.dropdown-trigger{border-radius: 20px;}
.search-device .btn {  border-radius: 0 20px 20px 0; padding: 0 17px !important;} 
.country-code-container .flag-dropdown{border-radius: 20px 0 0 20px; }
.search-device.not-found {  border: none  !important;}
.search-device input.add-input, .search-device input.add-input:focus, .search-device input.add-input:-webkit-autofill, .search-device input.add-input:-webkit-autofill:focus, .search-device input.add-input:-webkit-autofill:hover{border-top: #ccc solid 1px !important; border-bottom: #ccc solid 1px !important; margin-left: -3px;}
.search-device .material-icons.search-icon {  border: #ccc solid 1px;  height: 40px;  line-height: 40px;  width: 50px;
  text-align: right;  border-right: none;  border-radius: 20px 0 0 20px;     margin-left: -11px;}
  .right-nav a.logns, .right-nav a.support{border-radius: 20px;}
  .btn.btn-style-2{border-radius: 16px;}
  .btn, .btn-large, .btn-small, .btn-flat{    padding: 0 20px;}
/* round style end*/