.notificationDataTable .description {
    white-space: pre;
}

.notificationDataTable td {
    white-space: nowrap;
}

.notificationDataTable th {
    white-space: nowrap;
}

.notificationDataTable td.breakWord {
    white-space: normal;
    word-break: break-all;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.search-user {
    input[type=text] {
        text-transform: none;
        /* height: 40px !important;
        padding-top: 0px !important;
        padding-bottom: 0px !important; */

    }

    input:focus {
        box-shadow: none !important;
    }

    .auto-complete {
        width: 160px !important;
    }
}