.deviceStatusTable .description {
    white-space: pre;
}
.deviceStatusTable th {
    white-space: nowrap;
    font-weight: 700;
    padding: 8px;
}

.deviceStatusTable td {
    padding: 8px;
}
.deviceStatusTable td.breakWord {
    white-space: normal;
    word-break: break-all;
}