.deviceUpdateLogsTable .description {
    white-space: pre;
}

.deviceUpdateLogsTable th {
    white-space: nowrap;
}

.deviceUpdateLogsTable td.breakWord {
    white-space: normal;
    word-break: break-all;
}