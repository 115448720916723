.manufacturedDevicesTable .description {
    white-space: pre;
}

.manufacturedDevicesTable td {
    white-space: nowrap;
}

.manufacturedDevicesTable th {
    white-space: nowrap;
}

.manufacturedDevicesTable td.breakWord {
    white-space: normal;
    word-break: break-all;
}