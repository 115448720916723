@charset "utf-8";
/* CSS Document */
.new-layout-wraper {
  width: 100%;
}
.blue-bg,
.default-header-bg {
  background: #2874f0;
}
.new-layout-wraper .search-user-wraper input {
  background: #fff;
  margin: 0;
}
.new-layout-wraper .search-user-wraper label {
  color: #fff;
  min-width: 120px;
  font-size: 18px;
}
.new-layout-wraper .select-wrapper input.select-dropdown {
  height: 39px;
  line-height: normal;
  padding: 0 18px;
  width: 350px;
}
.bottom-shadow-1 {
  box-shadow: 0 3px 6px rgb(0, 0, 0, 0.16);
}
.top-menu-wrapper {
  background: #fff;
}
.top-menu-wrapper .top-navigation li {
  display: inline-block;
}
.top-menu-wrapper .top-navigation li a {
  padding: 20px 10px;
  display: inline-flex;
  align-items: center;
}
.top-menu-wrapper .top-navigation li a.active,
.top-menu-wrapper .top-navigation li a:hover {
  color: #2772ed;
}
ul.top-right-link {
  min-width: 150px;
  display: flex;
}
ul.top-right-link li a {
  color: #fff;
  line-height: 42px;
  padding: 0 20px 0 0;
  align-items: center;
  cursor: pointer;
}
ul.top-right-link .icn-support,
ul.top-right-link .icn-username {
  width: 30px;
  height: 30px;
  display: inline-block;
}
ul.top-right-link .icn-support {
  background: url(../../assets/support-icn.png) no-repeat;
}
ul.top-right-link .icn-username {
  background: url(../../assets/username-new-icon.png) no-repeat;
  margin-left: 7px;
}
ul.top-right-link a label {
  color: #fff;
}

.blue-bar-wrap {
  height: 80px;
}
.top-navigation li a {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
}
.tab-blue .tabs {
  width: auto;
  margin: 0;
  height: auto;
}
.tab-blue .tabs .indicator {
  display: none;
}
.tab-blue .tabs .tab {
  line-height: normal;
  height: auto;
  margin: 0;
}
.tab-blue .tabs .tab a,
.tab-blue .tabs .tab button {
  line-height: 30px;
  height: 30px;
  padding: 0 12px;
  border-radius: 6px;
  min-width: 80px;
  font-size: 16px;
  color: #7a7a7a;
  text-transform: capitalize;
  background: none;
  border: none;
  font-weight: 500;
}
.tab-blue .tabs .tab a.active,
.tab-blue .tabs .tab button:hover,
.tab-blue .tabs .tab button.active {
  background: #2874f0;
  color: #fff;
}
.select-wrapper span.caret {
  z-index: 9;
}
.space-fix {
  padding-top: 12px;
  padding-bottom: 8px;
}
.font-20 {
  font-size: 20px;
  line-height: 24px;
}
.dark-gray {
  color: #333;
}
.table-style-2 .table {
  border-collapse: collapse;
}
.table-style-2 .table th {
  font-size: 14px;
  font-weight: 500;
  padding: 8px;
  background: #f9f9f9;
}
.table-style-2 .table .tble-input {
  margin-bottom: 0;
  height: 30px;
  width: 100%;
  border: #cecece solid 1px;
  padding: 6px;
  box-sizing: border-box;
}
.table-style-2 .table td,
.table-style-2 .table th {
  border: #d6d6d6 solid 1px;
}
.table-style-2 .table td {
  padding: 4px;
  height: 30px;
  line-height: 30px;
}
.ml-15 {
  margin-left: 15px;
}
.table-style-2 .table tbody tr:nth-child(odd) {
  background: #f9f9f9;
}
.new-layout-wraper .select-wrapper input.select-dropdown {
  margin-bottom: 0;
}
.select-wrapper.style-2 input.select-dropdown {
  border: #cccccc solid 1px;
  border-radius: 20px;
  width: 250px;
  padding: 0 20px;
}
.select-wrapper.style-2 span.caret {
  right: 13px;
}
.detail-tab.reset-thumb {
  background: none;
}
.detail-tab.reset-thumb .tab a {
  background: none;
  border: #cccccc solid 1px;
  color: #333333;
}
.detail-tab.reset-thumb .tab a:hover,
.detail-tab.reset-thumb .tab a.active {
  background: #ff9f26;
}
.detail-tab.reset-thumb .tab a.active:after {
  border-top: 10px solid #ff9f26;
}
.heading-4 {
  height: 38px;
  position: relative;
}
.heading-4:before {
  background: #d6d6d6;
  height: 1px;
  position: absolute;
  width: 100%;
  top: 17px;
  content: "";
}
.heading-4 label {
  background: #ebebeb;
  font-size: 16px;
  font-weight: 500;
  border-radius: 0 19px 19px 0;
  padding: 0 30px 0 16px;
  line-height: 38px;
  display: inline-block;
  position: relative;
  z-index: 1;
}
.device-detail-wrap {
  border: #d6d6d6 solid 1px;
  padding: 27px 12px 15px 11px;
  border-radius: 6px;
}
.blu-link {
  text-decoration: underline;
}
.btn-style-4 {
  min-width: 175px;
}
.btn-style-4:hover {
  background: #ff9f26;
  color: #fff;
}
a.toggle-button {
  position: absolute;
  right: 20px;
  top: -2px;
}
a.toggle-button .material-icons {
  color: #202020;
  font-size: 30px;
}

/*top menu*/
.top-navigation .icon {
  width: 30px;
  height: 27px;
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 6px;
}
.top-navigation .icon.user-info {
  background-image: url(../../assets/new-user-info.png);
}
.top-navigation li a:hover .icon.user-info,
.top-navigation li a.active .icon.user-info {
  background-image: url(../../assets/new-user-info-blue.png);
}
.top-navigation .icon.device-info {
  background-image: url(../../assets/new-device-info.png);
}
.top-navigation li a:hover .icon.device-info,
.top-navigation li a.active .icon.device-info {
  background-image: url(../../assets/new-device-info-blue.png);
}
.top-navigation .icon.device-model {
  background-image: url(../../assets/new-device-modal.png);
}
.top-navigation li a:hover .icon.device-model,
.top-navigation li a.active .icon.device-model {
  background-image: url(../../assets/new-device-modal-blue.png);
}
.top-navigation .icon.statistics {
  background-image: url(../../assets/new-statistics.png);
}
.top-navigation li a:hover .icon.statistics,
.top-navigation li a.active .icon.statistics {
  background-image: url(../../assets/new-statistics-blue.png);
}
.top-navigation .icon.metrics {
  background-image: url(../../assets/new-products-metrics.png);
}
.top-navigation li a:hover .icon.metrics,
.top-navigation li a.active .icon.metrics {
  background-image: url(../../assets/new-products-metrics-blue.png);
}
.top-navigation .icon.mqtt-fota {
  background-image: url(../../assets/new-manage-fota.png);
}
.top-navigation li a:hover .icon.mqtt-fota,
.top-navigation li a.active .icon.mqtt-fota {
  background-image: url(../../assets/new-manage-fota-blue.png);
}


.top-navigation .icon.metrics-chrt {
  background-image: url(../../assets/metrics-chart-1.png);
}
.top-navigation li a:hover .icon.metrics-chrt,
.top-navigation li a.active .icon.metrics-chrt {
  background-image: url(../../assets/metrics-chart-1-blue.png);
}


.content-wrapper {
  max-width: 1260px;
  margin: 0 auto;
  width: 100%;
}
.blu-text {
  color: #2772ed !important;
}
.black-text {
  color: #000000 !important;
}
.new-layout-wraper .search-user-wraper.style-2 input {
  background: #eeeeee;
  height: 38px;
}

.max-300 {
  max-width: 300px;
}
.max-75-percent {
  max-width: 75%;
}
.search-user-wraper .enter-wrapper .add-input {
  width: 350px;
}
.search-user-wraper.style-2 .country-code-container {
  margin-right: 0 !important;
}
.search-user-wraper.style-2 .select-wrapper .caret {
  z-index: 9;
}
.wrap-all-search {
  width: 79%;
}
.enter-wrapper .search-icon {
  padding-top: 10px;
}
.user-information-bx .edit-link i.icn {
  color: #ffffff;
}

.user-information-bx .emailPopup-set {
  position: absolute;
  max-width: 182px;
  right: 26px;
  top: -74px;
}

.min-h-280 {
  min-height: 280px;
}

.user-information-bx .emailPopup-set .update-bx input.update-inpt {
  color: #fff;
  border-bottom: #ffffff solid 2px !important;
}
.new-layout-wraper .country-code-container .flag-dropdown {
  border-radius: 0;
}
.font-700 {
  font-weight: 700;
}

.new-layout-wraper .detail-tab.reset-thumb {
  background: none;
}
.new-layout-wraper .detail-tab.reset-thumb .tab a {
  border: #f5f5f5 solid 1px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-30 {
  padding-top: 30px;
}

.table-new-style {
  width: 100%;
  border: #efefef solid 1px;
}
.table-new-style th,
.table-new-style td {
  padding: 5px;
}
.table-new-style th {
  color: #ff9f26;
  font-weight: 700;
  font-size: 16px;
}
.table-new-style tbody td {
  font-size: 11px;
  line-height: 20x;
}

.new-layout-wraper .search-layout-2 .row {
  display: flex;
  margin: 0;
  justify-content: center;
}
.new-layout-wraper .search-layout-2 .row .s4 {
  width: auto;
  padding: 0 5px;
  margin: 0;
}
.new-layout-wraper .search-device.not-found {
  border: none;
}
.new-layout-wraper .search-layout-2 .page-title h3 {
  font-size: 14px;
}
.new-layout-wraper .control-capabilities .select-wrapper .caret {
  right: 0;
}
.table {
  float: none;
}
.table.style-4 tr th {
  font-size: 14px;
}
.table.style-4 tr td {
  font-size: 13px;
}
.detail-tab.reset-thumb .tab a .title {
  color: #333;
}
.row.reset-device-block {
  display: flex;
  justify-content: center;
}
.new-layout-wraper .row.reset-device-block .col.s4 {
  width: auto;
  margin-left: inherit;
}
.new-layout-wraper .row.reset-device-block label,
.new-layout-wraper .srch-device-reset label {
  min-width: 120px;
  font-size: 18px;
}

.new-layout-wraper .firmwareTableWrapper h4{font-size: 14px;  font-weight: 600;  margin-bottom: 14px;}
.border-1{ border: #cccccc solid 1px !important;}
.py-15{padding-top: 15px; padding-bottom: 15px;}
.radius-6{border-radius: 6px !important;}
.field-name{    font-size: 14px; color: #858585;  font-weight: 500; margin-bottom: 5px;}
.pt-20 {
  padding-top: 20px;
}

.rectangular .radius-6{border-radius:0 !important ;}
.mr-30{margin-right:30px;}
.width-auto{width: auto !important; min-width: auto !important;}


.new-layout-wraper .width-md .select-wrapper input.select-dropdown {width: 250px;}



