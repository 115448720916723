/* color theme*/
.purple-theme .login-wrapper {
  background: rgb(103, 58, 183);
  background: linear-gradient(to right, #4c15ad 0%, #673ab7 100%);
}
.purple-theme .btn,
.purple-theme .btn-large,
.purple-theme .btn-small {
  background-color: #673ab7;
}
.purple-theme .blu-bg,
.purple-theme .left-navbar {
  background: #673ab7;
}
.purple-theme .right-nav a.logns,
.purple-theme .block-title.style-2 h3,
.purple-theme .table.style-4 tr:nth-child(even) .reset-icon {
  color: #673ab7;
}
.purple-theme .detail-tab.reset-thumb .tab a:hover,
.purple-theme .detail-tab.reset-thumb .tab a.active {
  color: #fff;
  background: #673ab7;
}
.purple-theme .detail-tab.reset-thumb .tab a.active:after {
  border-top: 10px solid #673ab7;
}
.purple-theme .link-style,
.purple-theme .edit-link i.icn,
.purple-theme .orange-text {
  color: #673ab7 !important;
}
.purple-theme input.update-inpt {
  border-bottom: #673ab7 solid 2px !important;
}
.purple-theme .right-nav i.icn-logout {
  background: url(../src/assets/purple-logout-icn.png) no-repeat;
}
.purple-theme .right-nav a.logns span {
  color: #673ab7;
}




.grey-theme .login-wrapper {
  background: #333333;
  background: linear-gradient(to right, #333333 0%, #7b7979 100%);
}
.grey-theme .btn,
.grey-theme .btn-large,
.grey-theme .btn-small {
  background-color: #333333;
}
.grey-theme .blu-bg,
.grey-theme .left-navbar {
  background: #333333;
}
.grey-theme .right-nav a.logns,
.grey-theme .block-title.style-2 h3,
.grey-theme .table.style-4 tr:nth-child(even) .reset-icon {
  color: #333333;
}
.grey-theme .detail-tab.reset-thumb .tab a:hover,
.grey-theme .detail-tab.reset-thumb .tab a.active {
  color: #fff;
  background: #333333;
}
.grey-theme .detail-tab.reset-thumb .tab a.active:after {
  border-top: 10px solid #333333;
}
.grey-theme .link-style,
.grey-theme .edit-link i.icn,
.grey-theme .orange-text {
  color: #333333 !important;
}
.grey-theme input.update-inpt {
  border-bottom: #333333 solid 2px !important;
}
.grey-theme .right-nav i.icn-logout {
  background: url(../src/assets/purple-logout-icn.png) no-repeat;
}
.grey-theme .right-nav a.logns span {
  color: #333333;
}






.dark-blue-theme .login-wrapper {
  background: #131921;
  background: linear-gradient(to right, #17212d 0%, #131921 100%);
}
.dark-blue-theme .btn,
.dark-blue-theme .btn-large,
.dark-blue-theme .btn-small,
.dark-blue-theme .new-layout-wraper .default-header-bg,
.dark-blue-theme .user-information-bx {
  background-color: #131921;
}
.dark-blue-theme .login-wrapper .login-box .form-box .btn.add-btn:hover,
.dark-blue-theme .login-wrapper .login-box .form-box .btn.add-btn:focus {
  background: #ff9925 !important;
}
.dark-blue-theme .blu-bg,
.dark-blue-theme .left-navbar {
  background: #131921;
}
.dark-blue-theme .orange-bg,
.dark-blue-theme .wweek-box .name.active {
  background: #ff9925 !important;
}
/* .dark-blue-theme .right-nav a.logns,
.dark-blue-theme .block-title.style-2 h3,
.dark-blue-theme .table.style-4 tr:nth-child(even) .reset-icon {
  color: #ff9925;
} */
.dark-blue-theme .right-nav a.logns,
.dark-blue-theme .block-title.style-2 h3,
.dark-blue-theme .table.style-4 .reset-icon {
  color: #ff9925;
}
.dark-blue-theme .tab-reset .tabs .tab a:hover,
.dark-blue-theme .tab-reset .tabs .tab a.active,
.dark-blue-theme .detail-tab.reset-thumb .tab a:hover,
.dark-blue-theme .detail-tab.reset-thumb .tab a.active {
  color: #ff9925;
}
.dark-blue-theme .id-block .tabs .tab a:hover,
.dark-blue-theme .id-block .tabs .tab a.active:hover {
  background: #ff9925;
}
.dark-blue-theme .detail-tab.reset-thumb .tab a:hover,
.dark-blue-theme .detail-tab.reset-thumb .tab a.active {
  color: #fff;
  background: #131921;
}
.dark-blue-theme .detail-tab.reset-thumb .tab a.active:after {
  border-top: 10px solid#131921;
}
.dark-blue-theme .link-style,
.dark-blue-theme .edit-link i.icn,
.dark-blue-theme .orange-text {
  color: #131921 !important;
}
.dark-blue-theme input.update-inpt {
  border-bottom: #131921 solid 2px !important;
}
.dark-blue-theme .right-nav i.icn-logout {
  background: url(../src/assets/dark-blu-logout-icn.png) no-repeat;
}
.dark-blue-theme .right-nav a.logns span {
  color: #131921;
}

.pink-theme-secondary .login-wrapper .login-box .form-box .btn.add-btn:hover,
.pink-theme-secondary .login-wrapper .login-box .form-box .btn.add-btn:focus {
  background: #ea4b8a !important;
}
.pink-theme-secondary .id-block .tabs .tab a:hover,
.pink-theme-secondary .id-block .tabs .tab a.active:hover {
  background: #ea4b8a;
}
.pink-theme-secondary .orange-bg,
.pink-theme-secondary .wweek-box .name.active {
  background: #ea4b8a !important;
}
.pink-theme-secondary .tab-reset .tabs .tab a:hover,
.pink-theme-secondary .tab-reset .tabs .tab a.active,
.pink-theme-secondary .detail-tab.reset-thumb .tab a:hover,
.pink-theme-secondary .detail-tab.reset-thumb .tab a.active {
  color: #ea4b8a;
}


.black-theme-secondary .login-wrapper .login-box .form-box .btn.add-btn:hover,
.black-theme-secondary .login-wrapper .login-box .form-box .btn.add-btn:focus {
  background: #000 !important;
}
.black-theme-secondary .id-block .tabs .tab a:hover,
.black-theme-secondary .id-block .tabs .tab a.active:hover {
  background: #000;
}
.black-theme-secondary .orange-bg,
.black-theme-secondary .wweek-box .name.active {
  background: #000 !important;
}
.black-theme-secondary .tab-reset .tabs .tab a:hover,
.black-theme-secondary .tab-reset .tabs .tab a.active,
.black-theme-secondary .detail-tab.reset-thumb .tab a:hover,
.black-theme-secondary .detail-tab.reset-thumb .tab a.active {
  color: #000;
}

/*magenta theme*/
.magenta-theme .login-wrapper {
  background: #581845;
  background: linear-gradient(to right, #460f35 0%, #581845 100%);
}
.magenta-theme .btn,
.magenta-theme .btn-large,
.magenta-theme .btn-small,
.magenta-theme .user-information-bx,
.magenta-theme .new-layout-wraper .default-header-bg {
  background-color: #581845;
}
.magenta-theme .login-wrapper .login-box .form-box .btn.add-btn:hover,
.magenta-theme .login-wrapper .login-box .form-box .btn.add-btn:focus {
  background: #ff9925 !important;
}
.magenta-theme .blu-bg,
.magenta-theme .left-navbar {
  background: #581845;
}
.magenta-theme .orange-bg,
.magenta-theme .wweek-box .name.active {
  background: #ff9925 !important;
}
.magenta-theme .right-nav a.logns,
.magenta-theme .block-title.style-2 h3,
.magenta-theme .table.style-4 tr:nth-child(even) .reset-icon {
  color: #ff9925;
}
.magenta-theme .tab-reset .tabs .tab a:hover,
.magenta-theme .tab-reset .tabs .tab a.active,
.magenta-theme .detail-tab.reset-thumb .tab a:hover,
.magenta-theme .detail-tab.reset-thumb .tab a.active {
  color: #ff9925;
}
.magenta-theme .id-block .tabs .tab a:hover,
.magenta-theme .id-block .tabs .tab a.active:hover {
  background: #ff9925;
}
.magenta-theme .detail-tab.reset-thumb .tab a:hover,
.magenta-theme .detail-tab.reset-thumb .tab a.active {
  color: #fff;
  background: #581845;
}
.magenta-theme .detail-tab.reset-thumb .tab a.active:after {
  border-top: 10px solid#581845;
}
.magenta-theme .link-style,
.magenta-theme .edit-link i.icn,
.magenta-theme .orange-text {
  color: #581845 !important;
}
.magenta-theme input.update-inpt {
  border-bottom: #581845 solid 2px !important;
}
.magenta-theme .right-nav i.icn-logout {
  background: url(../src/assets/magenta-logout-icn.png) no-repeat;
}
.magenta-theme .right-nav a.logns span {
  color: #581845;
}

/*red theme*/
.red-theme .login-wrapper {
  background: #bd1d38;
  background: linear-gradient(to right, #9f0620 0%, #bd1d38 100%);
}
.red-theme .btn,
.red-theme .btn-large,
.red-theme .btn-small,
.red-theme .user-information-bx,
.red-theme .new-layout-wraper .default-header-bg {
  background-color: #bd1d38;
}
.red-theme .login-wrapper .login-box .form-box .btn.add-btn:hover,
.red-theme .login-wrapper .login-box .form-box .btn.add-btn:focus {
  background: #ff9925 !important;
}
.red-theme .blu-bg,
.red-theme .left-navbar {
  background: #bd1d38;
}
.red-theme .orange-bg,
.red-theme .wweek-box .name.active {
  background: #ff9925 !important;
}
.red-theme .right-nav a.logns,
.red-theme .block-title.style-2 h3,
.red-theme .table.style-4 tr:nth-child(even) .reset-icon {
  color: #ff9925;
}
.red-theme .tab-reset .tabs .tab a:hover,
.red-theme .tab-reset .tabs .tab a.active,
.red-theme .detail-tab.reset-thumb .tab a:hover,
.red-theme .detail-tab.reset-thumb .tab a.active {
  color: #ff9925;
}
.red-theme .id-block .tabs .tab a:hover,
.red-theme .id-block .tabs .tab a.active:hover {
  background: #ff9925;
}
.red-theme .detail-tab.reset-thumb .tab a:hover,
.red-theme .detail-tab.reset-thumb .tab a.active {
  color: #fff;
  background: #bd1d38;
}
.red-theme .detail-tab.reset-thumb .tab a.active:after {
  border-top: 10px solid#BD1D38;
}
.red-theme .link-style,
.red-theme .edit-link i.icn,
.red-theme .orange-text {
  color: #bd1d38 !important;
}
.red-theme input.update-inpt {
  border-bottom: #bd1d38 solid 2px !important;
}
.red-theme .right-nav i.icn-logout {
  background: url(../src/assets/red-logout-icn.png) no-repeat;
}
.red-theme .right-nav a.logns span {
  color: #bd1d38;
}

/*orange theme*/
.orange-theme .login-wrapper {
  background: #ef6c00;
  background: linear-gradient(to right, #cc5e02 0%, #ef6c00 100%);
}
.orange-theme .btn,
.orange-theme .btn-large,
.orange-theme .btn-small {
  background-color: #ef6c00;
}
.orange-theme .login-wrapper .login-box .form-box .btn.add-btn:hover,
.orange-theme .login-wrapper .login-box .form-box .btn.add-btn:focus {
  background: #ff9925 !important;
}
.orange-theme .blu-bg,
.orange-theme .left-navbar {
  background: #ef6c00;
}
.orange-theme .orange-bg,
.orange-theme .wweek-box .name.active {
  background: #ff9925 !important;
}
.orange-theme .right-nav a.logns,
.orange-theme .block-title.style-2 h3,
.orange-theme .table.style-4 tr:nth-child(even) .reset-icon {
  color: #ff9925;
}
.orange-theme .tab-reset .tabs .tab a:hover,
.orange-theme .tab-reset .tabs .tab a.active,
.orange-theme .detail-tab.reset-thumb .tab a:hover,
.orange-theme .detail-tab.reset-thumb .tab a.active {
  color: #ff9925;
}
.orange-theme .id-block .tabs .tab a:hover,
.orange-theme .id-block .tabs .tab a.active:hover {
  background: #ff9925;
}
.orange-theme .detail-tab.reset-thumb .tab a:hover,
.orange-theme .detail-tab.reset-thumb .tab a.active {
  color: #fff;
  background: #ef6c00;
}
.orange-theme .detail-tab.reset-thumb .tab a.active:after {
  border-top: 10px solid#EF6C00;
}
.orange-theme .link-style,
.orange-theme .edit-link i.icn,
.orange-theme .orange-text {
  color: #ef6c00 !important;
}
.orange-theme input.update-inpt {
  border-bottom: #ef6c00 solid 2px !important;
}
.orange-theme .right-nav i.icn-logout {
  background: url(../src/assets/orange-logout-icn.png) no-repeat;
}
.orange-theme .right-nav a.logns span {
  color: #ef6c00;
}

/*green theme*/
.green-theme .login-wrapper {
  background: #388e3c;
  background: linear-gradient(to right, #1d6120 0%, #388e3c 100%);
}
.green-theme .btn,
.green-theme .btn-large,
.green-theme .btn-small,
.green-theme .new-layout-wraper .default-header-bg,
.green-theme .user-information-bx {
  background-color: #388e3c;
}
.green-theme .login-wrapper .login-box .form-box .btn.add-btn:hover,
.green-theme .login-wrapper .login-box .form-box .btn.add-btn:focus {
  background: #ff9925 !important;
}
.green-theme .blu-bg,
.green-theme .left-navbar {
  background: #388e3c;
}
.green-theme .orange-bg,
.green-theme .wweek-box .name.active {
  background: #ff9925 !important;
}
.green-theme .right-nav a.logns,
.green-theme .block-title.style-2 h3,
.green-theme .table.style-4 tr:nth-child(even) .reset-icon {
  color: #ff9925;
}
.green-theme .tab-reset .tabs .tab a:hover,
.green-theme .tab-reset .tabs .tab a.active,
.green-theme .detail-tab.reset-thumb .tab a:hover,
.green-theme .detail-tab.reset-thumb .tab a.active {
  color: #ff9925;
}
.green-theme .id-block .tabs .tab a:hover,
.green-theme .id-block .tabs .tab a.active:hover {
  background: #ff9925;
}
.green-theme .detail-tab.reset-thumb .tab a:hover,
.green-theme .detail-tab.reset-thumb .tab a.active {
  color: #fff;
  background: #388e3c;
}
.green-theme .detail-tab.reset-thumb .tab a.active:after {
  border-top: 10px solid#388E3C;
}
.green-theme .link-style,
.green-theme .edit-link i.icn,
.green-theme .orange-text {
  color: #388e3c !important;
}
.green-theme input.update-inpt {
  border-bottom: #388e3c solid 2px !important;
}
.green-theme .right-nav i.icn-logout {
  background: url(../src/assets/green-logout-icn.png) no-repeat;
}
.green-theme .right-nav a.logns span {
  color: #388e3c;
}

/*lime theme*/
.lime-theme .login-wrapper {
  background: #afb42b;
  background: linear-gradient(to right, #9a9f09 0%, #afb42b 100%);
}
.lime-theme .btn,
.lime-theme .btn-large,
.lime-theme .btn-small,
.lime-theme .new-layout-wraper .default-header-bg,
.lime-theme .user-information-bx {
  background-color: #afb42b;
}
.lime-theme .login-wrapper .login-box .form-box .btn.add-btn:hover,
.lime-theme .login-wrapper .login-box .form-box .btn.add-btn:focus {
  background: #ff9925 !important;
}
.lime-theme .blu-bg,
.lime-theme .left-navbar {
  background: #afb42b;
}
.lime-theme .orange-bg,
.lime-theme .wweek-box .name.active {
  background: #ff9925 !important;
}
.lime-theme .right-nav a.logns,
.lime-theme .block-title.style-2 h3,
.lime-theme .table.style-4 tr:nth-child(even) .reset-icon {
  color: #ff9925;
}
.lime-theme .tab-reset .tabs .tab a:hover,
.lime-theme .tab-reset .tabs .tab a.active,
.lime-theme .detail-tab.reset-thumb .tab a:hover,
.lime-theme .detail-tab.reset-thumb .tab a.active {
  color: #ff9925;
}
.lime-theme .id-block .tabs .tab a:hover,
.lime-theme .id-block .tabs .tab a.active:hover {
  background: #ff9925;
}
.lime-theme .detail-tab.reset-thumb .tab a:hover,
.lime-theme .detail-tab.reset-thumb .tab a.active {
  color: #fff;
  background: #afb42b;
}
.lime-theme .detail-tab.reset-thumb .tab a.active:after {
  border-top: 10px solid#AFB42B;
}
.lime-theme .link-style,
.lime-theme .edit-link i.icn,
.lime-theme .orange-text {
  color: #afb42b !important;
}
.lime-theme input.update-inpt {
  border-bottom: #afb42b solid 2px !important;
}
.lime-theme .right-nav i.icn-logout {
  background: url(../src/assets/lime-logout-icn.png) no-repeat;
}
.lime-theme .right-nav a.logns span {
  color: #afb42b;
}

.purple-theme-secondary .login-wrapper .login-box .form-box .btn.add-btn:hover,
.purple-theme-secondary .login-wrapper .login-box .form-box .btn.add-btn:focus {
  background: #881bce !important;
}
.purple-theme-secondary .id-block .tabs .tab a:hover,
.purple-theme-secondary .id-block .tabs .tab a.active:hover,
.purple-theme-secondary .tab-blue .tabs .tab a.active,
.purple-theme-secondary .tab-blue .tabs .tab button:hover,
.purple-theme-secondary .tab-blue .tabs .tab button.active {
  background: #881bce;
}
.purple-theme-secondary .orange-bg,
.purple-theme-secondary .wweek-box .name.active {
  background: #881bce !important;
}
.purple-theme-secondary .tab-reset .tabs .tab a:hover,
.purple-theme-secondary .tab-reset .tabs .tab a.active,
.purple-theme-secondary .detail-tab.reset-thumb .tab a:hover,
.purple-theme-secondary .detail-tab.reset-thumb .tab a.active {
  color: #881bce;
}

.dark-blu-theme-secondary
  .login-wrapper
  .login-box
  .form-box
  .btn.add-btn:hover,
.dark-blu-theme-secondary
  .login-wrapper
  .login-box
  .form-box
  .btn.add-btn:focus {
  background: #131921 !important;
}
.dark-blu-theme-secondary .id-block .tabs .tab a:hover,
.dark-blu-theme-secondary .id-block .tabs .tab a.active:hover,
.dark-blu-theme-secondary .tab-blue .tabs .tab a.active,
.dark-blu-theme-secondary .tab-blue .tabs .tab button:hover,
.dark-blu-theme-secondary .tab-blue .tabs .tab button.active {
  background: #131921;
}
.dark-blu-theme-secondary .orange-bg,
.dark-blu-theme-secondary .wweek-box .name.active {
  background: #131921 !important;
}
.dark-blu-theme-secondary .tab-reset .tabs .tab a:hover,
.dark-blu-theme-secondary .tab-reset .tabs .tab a.active,
.dark-blu-theme-secondary .detail-tab.reset-thumb .tab a:hover,
.dark-blu-theme-secondary .detail-tab.reset-thumb .tab a.active {
  color: #131921;
}

.light-blu-theme-secondary
  .login-wrapper
  .login-box
  .form-box
  .btn.add-btn:hover,
.light-blu-theme-secondary
  .login-wrapper
  .login-box
  .form-box
  .btn.add-btn:focus {
  background: #3d648e !important;
}
.light-blu-theme-secondary .id-block .tabs .tab a:hover,
.light-blu-theme-secondary .id-block .tabs .tab a.active:hover,
.light-blu-theme-secondary .tab-blue .tabs .tab a.active,
.light-blu-theme-secondary .tab-blue .tabs .tab button:hover,
.light-blu-theme-secondary .tab-blue .tabs .tab button.active {
  background: #3d648e;
}
.light-blu-theme-secondary .orange-bg,
.light-blu-theme-secondary .wweek-box .name.active {
  background: #3d648e !important;
}
.light-blu-theme-secondary .tab-reset .tabs .tab a:hover,
.light-blu-theme-secondary .tab-reset .tabs .tab a.active,
.light-blu-theme-secondary .detail-tab.reset-thumb .tab a:hover,
.light-blu-theme-secondary .detail-tab.reset-thumb .tab a.active {
  color: #3d648e;
}

.lime-theme-secondary .login-wrapper .login-box .form-box .btn.add-btn:hover,
.lime-theme-secondary .login-wrapper .login-box .form-box .btn.add-btn:focus {
  background: #afb42b !important;
}
.lime-theme-secondary .id-block .tabs .tab a:hover,
.lime-theme-secondary .id-block .tabs .tab a.active:hover {
  background: #afb42b;
}
.lime-theme-secondary .orange-bg,
.lime-theme-secondary .wweek-box .name.active {
  background: #afb42b !important;
}
.lime-theme-secondary .tab-reset .tabs .tab a:hover,
.lime-theme-secondary .tab-reset .tabs .tab a.active,
.lime-theme-secondary .detail-tab.reset-thumb .tab a:hover,
.lime-theme-secondary .detail-tab.reset-thumb .tab a.active {
  color: #afb42b;
}

.green-theme-secondary .login-wrapper .login-box .form-box .btn.add-btn:hover,
.green-theme-secondary .login-wrapper .login-box .form-box .btn.add-btn:focus {
  background: #388e3c !important;
}
.green-theme-secondary .id-block .tabs .tab a:hover,
.green-theme-secondary .id-block .tabs .tab a.active:hover,
.green-theme-secondary .tab-blue .tabs .tab a.active,
.green-theme-secondary .tab-blue .tabs .tab button:hover,
.green-theme-secondary .tab-blue .tabs .tab button.active {
  background: #388e3c;
}
.green-theme-secondary .orange-bg,
.green-theme-secondary .wweek-box .name.active {
  background: #388e3c !important;
}
.green-theme-secondary .tab-reset .tabs .tab a:hover,
.green-theme-secondary .tab-reset .tabs .tab a.active,
.green-theme-secondary .detail-tab.reset-thumb .tab a:hover,
.green-theme-secondary .detail-tab.reset-thumb .tab a.active {
  color: #388e3c;
}

.orange-theme-secondary .login-wrapper .login-box .form-box .btn.add-btn:hover,
.orange-theme-secondary .login-wrapper .login-box .form-box .btn.add-btn:focus {
  background: #ef6c00 !important;
}
.orange-theme-secondary .id-block .tabs .tab a:hover,
.orange-theme-secondary .id-block .tabs .tab a.active:hover,
.orange-theme-secondary .tab-blue .tabs .tab a.active,
.orange-theme-secondary .tab-blue .tabs .tab button:hover,
.orange-theme-secondary .tab-blue .tabs .tab button.active {
  background: #ef6c00;
}
.orange-theme-secondary .orange-bg,
.orange-theme-secondary .wweek-box .name.active {
  background: #ef6c00 !important;
}
.orange-theme-secondary .tab-reset .tabs .tab a:hover,
.orange-theme-secondary .tab-reset .tabs .tab a.active,
.orange-theme-secondary .detail-tab.reset-thumb .tab a:hover,
.orange-theme-secondary .detail-tab.reset-thumb .tab a.active {
  color: #ef6c00;
}

/* color theme*/

.detail-tab.reset-thumb li.tab a.active .title,
.detail-tab.reset-thumb li.tab a:hover .title {
  color: #fff;
}

.orange-theme-secondary .new-layout-wraper .table-style-2 .table .tble-input {
  border: #ef6c00 solid 1px;
}
.orange-theme-secondary .new-layout-wraper .btn.btn-style-4,
.orange-theme-secondary .new-layout-wraper .btn.btn-style-4:focus {
  color: #ef6c00;
  border: #ef6c00 solid 1px;
}
.orange-theme-secondary .new-layout-wraper .tab-blue .tabs .tab a.active,
.tab-blue .tabs .tab button:hover,
.orange-theme-secondary .new-layout-wraper .tab-blue .tabs .tab button.active {
  background: #ef6c00;
}
.orange-theme-secondary a,
.orange-theme-secondary .link-style {
  color: #ef6c00;
}

.purple-theme-secondary .new-layout-wraper .table-style-2 .table .tble-input {
  border: #881bce solid 1px;
}
.purple-theme-secondary .new-layout-wraper .btn.btn-style-4,
.purple-theme-secondary .new-layout-wraper .btn.btn-style-4:focus {
  color: #881bce;
  border: #881bce solid 1px;
}
.purple-theme-secondary .new-layout-wraper .tab-blue .tabs .tab a.active,
.tab-blue .tabs .tab button:hover,
.purple-theme-secondary .new-layout-wraper .tab-blue .tabs .tab button.active {
  background: #881bce;
}
.purple-theme-secondary a,
.purple-theme-secondary .link-style {
  color: #881bce;
}

.dark-blu-theme-secondary .new-layout-wraper .table-style-2 .table .tble-input {
  border: #232f3e solid 1px;
}
.dark-blu-theme-secondary .new-layout-wraper .btn.btn-style-4,
.dark-blu-theme-secondary .new-layout-wraper .btn.btn-style-4:focus {
  color: #232f3e;
  border: #232f3e solid 1px;
}
.dark-blu-theme-secondary .new-layout-wraper .tab-blue .tabs .tab a.active,
.tab-blue .tabs .tab button:hover,
.dark-blu-theme-secondary
  .new-layout-wraper
  .tab-blue
  .tabs
  .tab
  button.active {
  background: #232f3e;
}
.dark-blu-theme-secondary a,
.dark-blu-theme-secondary .link-style {
  color: #232f3e;
}

.light-blu-theme-secondary
  .new-layout-wraper
  .table-style-2
  .table
  .tble-input {
  border: #3d648e solid 1px;
}
.light-blu-theme-secondary .new-layout-wraper .btn.btn-style-4,
.light-blu-theme-secondary .new-layout-wraper .btn.btn-style-4:focus {
  color: #3d648e;
  border: #3d648e solid 1px;
}
.light-blu-theme-secondary .new-layout-wraper .tab-blue .tabs .tab a.active,
.tab-blue .tabs .tab button:hover,
.light-blu-theme-secondary
  .new-layout-wraper
  .tab-blue
  .tabs
  .tab
  button.active {
  background: #3d648e;
}
.light-blu-theme-secondary a,
.light-blu-theme-secondary .link-style {
  color: #232f3e;
}

.lime-theme-secondary .new-layout-wraper .table-style-2 .table .tble-input {
  border: #afb42b solid 1px;
}
.lime-theme-secondary .new-layout-wraper .btn.btn-style-4,
.lime-theme-secondary .new-layout-wraper .btn.btn-style-4:focus {
  color: #afb42b;
  border: #afb42b solid 1px;
}
.lime-theme-secondary .new-layout-wraper .tab-blue .tabs .tab a.active,
.tab-blue .tabs .tab button:hover,
.lime-theme-secondary .new-layout-wraper .tab-blue .tabs .tab button.active {
  background: #afb42b;
}
.lime-theme-secondary a,
.lime-theme-secondary .link-style {
  color: #afb42b;
}

.green-theme-secondary .new-layout-wraper .table-style-2 .table .tble-input {
  border: #388e3c solid 1px;
}
.green-theme-secondary .new-layout-wraper .btn.btn-style-4,
.green-theme-secondary .new-layout-wraper .btn.btn-style-4:focus {
  color: #388e3c;
  border: #388e3c solid 1px;
}
.green-theme-secondary .new-layout-wraper .tab-blue .tabs .tab a.active,
.tab-blue .tabs .tab button:hover,
.green-theme-secondary .new-layout-wraper .tab-blue .tabs .tab button.active {
  background: #388e3c;
}
.green-theme-secondary a,
.green-theme-secondary .link-style {
  color: #388e3c;
}

.pink-theme-secondary .new-layout-wraper .table-style-2 .table .tble-input {
  border: #ea4b8a solid 1px;
}
.pink-theme-secondary .new-layout-wraper .btn.btn-style-4,
.pink-theme-secondary .new-layout-wraper .btn.btn-style-4:focus {
  color: #ea4b8a;
  border: #ea4b8a solid 1px;
}
.pink-theme-secondary .new-layout-wraper .tab-blue .tabs .tab a.active,
.tab-blue .tabs .tab button:hover,
.pink-theme-secondary .new-layout-wraper .tab-blue .tabs .tab button.active {
  background: #ea4b8a;
}
.pink-theme-secondary a,
.pink-theme-secondary .link-style {
  color: #ea4b8a;
}
