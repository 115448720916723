.deviceDiagnosticsTable .description {
    white-space: pre;
}

.deviceDiagnosticsTable th {
    white-space: nowrap;
    font-weight: 700;
    padding: 8px;
}
.deviceDiagnosticsTable td {
    padding: 8px;
}
.deviceDiagnosticsTable td.breakWord {
    white-space: normal;
    word-break: break-all;
}