.feedbacktable .description {
  white-space: pre;
}
.feedbacktable td {
  white-space: nowrap;
}
.feedbacktable th {
  white-space: nowrap;
}

.feedbacktable td.breakWord {
  white-space: normal;
  word-break: break-all;
}

.displayNone {
  display: none;
}
