/* rectangular style*/
.no-bg, .no-bg:hover, .no-bg:focus{background:none;}
.no-bg{background: none;}
.blu-bg{background: #2874F0;}
.bottom-shadow-1{box-shadow: none;}
.content-block.bottom-shadow{box-shadow: none; border: #DBDBDB solid 1px; border-radius: 0;}
.left-navbar.fixed.round-style {  top: 65px;  width: 250px;  left: 0;  bottom: 6px;  height: auto;}
.left-navbar.round-style ul.left-navigation li a:hover, .left-navbar.round-style ul.left-navigation li a.active { background: rgba(0,0,0,0.12); color: #fff; }
.left-navbar.round-style ul.left-navigation li a{    padding: 0 16px;}
.left-navbar.fixed.round-style .easter-eggs{font-size: 11px;}
.top-bar-right-style-1{margin: 0 0 0 250px;}
.left-navbar.round-style .vertical-block{height: 100%;}
.radius-20{border-radius: 0;}
.left-navbar.fixed.round-style{bottom: 0;}
.model-srch.reset .select-dropdown.dropdown-trigger{border-radius:0;}
.search-device .btn {  border-radius: 0; padding: 0 17px !important;} 
.country-code-container .flag-dropdown{border-radius:0; }
.search-device.not-found {  border: none;}
.search-device input.add-input, .search-device input.add-input:focus, .search-device input.add-input:-webkit-autofill, .search-device input.add-input:-webkit-autofill:focus, .search-device input.add-input:-webkit-autofill:hover{border-top: #ccc solid 1px !important; border-bottom: #ccc solid 1px !important; margin-left: -3px;}
.search-device .material-icons.search-icon {  border: #ccc solid 1px;  height: 40px;  line-height: 40px;  width: 50px;
  text-align: right;  border-right: none;  border-radius: 0;     margin-left: -11px;}
  .right-nav a.logns, .right-nav a.support,  .login-wrapper .login-box .form-box, .login-wrapper .login-box .form-box .btn.add-btn{border-radius: 0;}
  .btn.btn-style-2, .btn.btn-style-3, .content-block, .tabs.detail-tab.reset-thumb a, .radius-bottom-16, .radius-16, .tabs .tab a, .tabs .tab a:hover, .easter-eggs, .detail-tab.reset-thumb, .add-input.xl{border-radius: 0;}
  .btn, .btn-large, .btn-small, .btn-flat{    padding: 0 20px;}
  .login-wrapper .login-box .form-box .add-input.xl{border-radius: 0 !important;}
  /* rectangular style*/