.deviceDealerTable .description {
    white-space: pre;
}

.deviceDealerTable td {
    white-space: nowrap;
}

.deviceDealerTable th {
    white-space: nowrap;
}

.deviceDealerTable td.breakWord {
    white-space: normal;
    word-break: break-all;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}