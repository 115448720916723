/* Global Styles */
@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap");

:root {
  --primary-color: #17a2b8;
  --dark-color: #343a40;
  --light-color: #f4f4f4;
  --danger-color: #dc3545;
  --success-color: #28a745;
}

::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* border-radius: 7px; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 7px;
  transition: 0.2s;
}

::-webkit-scrollbar-thumb:hover {
  background: #777;
  width: 15px;
  height: 15px;
}

.color-white {
  color: #fff !important;
}

.ls-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.ls-popup-inner {
  padding: 5px 5px 5px 5px;
}

.show-select select {
  display: block !important;
}

.ls-popup-close {
  float: right;
}

.separator-light {
  border-bottom: #404147 solid 1px;
  margin-bottom: 10px;
}

.lnkBtn {
  background: none !important;
  border: none;
  padding: 0 !important;
  cursor: pointer;
}

.alert {
  padding: 0.8rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: var(--light-color);
  color: #fff;
}

.btn-primary,
.bg-primary,
.badge-primary,
.alert-primary {
  background: var(--primary-color);
  color: #fff;
}

.btn-light,
.bg-light,
.badge-light,
.alert-light {
  background: var(--light-color);
  color: #333;
}

.btn-dark,
.bg-dark,
.badge-dark,
.alert-dark {
  background: var(--dark-color);
  color: #fff;
}

.alert-show {
  background: #48c248 !important;
  color: #fff;
}

.btn-danger,
.bg-danger,
.badge-danger,
.alert-danger {
  background: var(--danger-color);
  color: #fff;
}

.btn-success,
.bg-success,
.badge-success,
.alert-success {
  background: var(--success-color);
  color: #fff;
}

.btn-white,
.bg-white,
.badge-white,
.alert-white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
}

.btn:hover {
  opacity: 0.8;
}

.bg-light,
.badge-light {
  border: #ccc solid 1px;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.right-content-wraper .mid-seprator {
  display: none;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Css for Project */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  font-family: "Roboto", sans-serif;
  line-height: 18px;
  color: #000000;
  font-size: 14px;
  background: #eee;
}

ol,
ul {
  list-style: none;
}

.login-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  position: relative;
  background: linear-gradient(to right, #00467c 0%, #005ea9 100%);
}

.login-wrapper .login-box {
  width: 382px;
}

.login-wrapper .login-box .form-box {
  background: #fff;
  padding: 26px 40px;
  border-radius: 8px;
  margin-bottom: 16px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
}

.login-wrapper .login-box .form-box label {
  position: relative;
  transform: translateY(0);
  left: 0;
  color: #333333;
  font-size: 14px;
  font-weight: 500;
  display: block;
  margin-bottom: 8px;
}

.login-box .form-box input.add-input::placeholder {
  color: #808085;
}

.login-wrapper .login-box .form-box .input-field {
  margin: 0;
}

.login-wrapper .login-box .form-box .row {
  margin-bottom: 34px;
}

.login-wrapper .login-box .form-box .btn.add-btn {
  box-shadow: none;
  border-radius: 40px;
  font-weight: 600;
  text-transform: capitalize;
}

.login-wrapper .login-box .form-box .btn.add-btn:hover,
.login-wrapper .login-box .form-box .btn.add-btn:focus {
  background: #ff8802 !important;
  opacity: 1;
}

.login-wrapper .login-box .sign-title h2 {
  color: #fff;
  font-size: 28px;
  line-height: 28px;
  margin-bottom: 15px;
  font-weight: 400;
}

.login-wrapper .login-box .form-box [type="checkbox"]:checked+span:not(.lever):before {
  border-right: 2px solid #ff8802;
  border-bottom: 2px solid #ff8802;
}

/***************inner page css*******************/
.main-wrapper,
.main-wrapper .container {
  width: 100%;
  max-width: none;
}

.top-bar {
  background: #0062b0;
  height: 64px;
  padding: 0 29px 0 0;
}

.top-bar.fixed {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2200;
  box-shadow: none;
}

.top-bar-right-style-1 {
  width: calc(100% - 220px);
  margin: 0 0 0 253px;
  padding: 0 18px 0 18px;
  height: 64px;
}

a.logo-inner {
  display: inline-block;
  margin-top: 5px;
}

.user-detail .user-picture {
  width: 37px;
  height: 37px;
  border-radius: 50%;
  margin: 0 5px 0 8px;
}

.user-detail .dropdown-content.user-content {
  top: 50px !important;
}

.user-detail .dropdown-content.user-content li a {
  color: #fff;
  border-bottom: #272934 solid 1px;
}

.user-detail .dropdown-content.user-content li {
  background: #4c4d58;
}

.user-detail .dropdown-content.user-content li:hover {
  background: #2c2d37;
}

.user-detail .user-name {
  font-size: 13px;
}

.user-detail .user-picture img {
  border-radius: 50%;
}

.left-navbar.fixed {
  position: fixed;
  left: 0;
  top: 64px;
  z-index: 1;
}

.left-navbar {
  width: 250px;
  background: #1976d2;
  height: 100%;
}

.left-navbar .vertical-block {
  position: relative;
  height: calc(100% - 60px);
  overflow: auto;

}

.left-navbar ul.left-navigation {
  margin-top: 22px;
}

.left-navbar ul.left-navigation li a {
  color: #fff;
  padding: 0 28px;
  height: 44px;
  font-weight: 400;
  line-height: 44px;
  display: flex;
  align-items: center;
  font-size: 16px;
  transition: all 0.3s;
}

.left-navbar ul.left-navigation li a .left-icon {
  margin-right: 13px;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: left;
}

.left-navbar ul.left-navigation li a .left-icon.user-info {
  background-image: url(../src/assets/left-home-icn.png);
}

.left-navbar ul.left-navigation li a .left-icon.device-info {
  background-image: url(../src/assets/left-dev-info-icn.png);
}

.left-navbar ul.left-navigation li a .left-icon.device-model {
  background-image: url(../src/assets/left-dev-model-icn.png);
}

.left-navbar ul.left-navigation li a .left-icon.notification {
  background-image: url(../src/assets/notification.svg);
}

.left-navbar ul.left-navigation li a .left-icon.statistics {
  background-image: url(../src/assets/statistics-icon.png);
}

.left-navbar ul.left-navigation li a .left-icon.metrics {
  background-image: url(../src/assets/metrics-icon.png);
}

.left-navbar ul.left-navigation li a .left-icon.feedback {
  background-image: url(../src/assets/feedback-new.png);
}

.left-navbar ul.left-navigation li a .left-icon.mqtt-fota {
  background-image: url(../src/assets/fota-icon.png);
  background-size: 100%;
}

.left-navbar ul.left-navigation li a .left-icon.metrics-chart {
  background-image: url(../src/assets/metrics-chart-white.png);
  background-size: 100%;
}

.left-navbar ul.left-navigation li a:hover,
.left-navbar ul.left-navigation li a.active {
  background: rgba(0, 0, 0, 0.12);
  color: #fff;
  box-shadow: #fff 2px 0 0px 0 inset;
}

.copyright-block {
  font-size: 12px;
  color: #fff;
  text-align: center;
  padding: 0 35px;
  z-index: 99;
}

.easter-eggs {
  font-size: 12px;
  color: #fff;
  text-align: center;
  padding: 0 10px;
  z-index: 99;
  margin-bottom: 22px;
  background: rgba(0, 0, 0, 0.12);
  padding: 12px 16px;
  border-radius: 8px;
}

.right-content-wraper {
  width: calc(100% - 248px);
  margin: 80px 0 0 248px;
  padding: 0 18px 0 18px;
}

.right-content-wraper .container {
  width: 100%;
  margin: 0;
  max-width: none;
}

.page-title h3 {
  font-size: 18px;
  font-weight: 700;
}

.page-title h3 span {
  color: #909195;
  display: inline-block;
}

.page-title .sub-title {
  color: #6f7175;
  font-size: 12px;
  font-weight: 400;
}

.search-device {
  border: #3c3e44 solid 1px;
  display: flex;
  align-items: center;
  border-radius: 3px;
  color: #7a7c80;
  padding: 0 0 0 10px;
  width: 385px;
  margin: 0 0 0 14px;
}

.search-device input[type="text"]:not(.browser-default) {
  border-bottom: none;
}

.search-device input[type="text"] {
  border: none;
  color: #000000;
  font-weight: 500;
}

input::placeholder {
  color: #b0b0b0 !important;
}

.content-block {
  background: #fff;
  border-radius: 6px;
  margin-bottom: 15px;
}

.table.device-list-table tr td,
.table.device-list-table tr th {
  padding: 7px 18px;
  vertical-align: middle;
}

.table.device-list-table {
  margin-bottom: 70px;
}

.table.device-list-table .list-profile-pic {
  width: 36px;
  height: 36px;
  display: inline-block;
  margin-right: 14px;
}

.table.device-list-table .list-profile-pic img {
  height: 100%;
  width: 100%;
  border-radius: 4px;
}

.table.device-list-table tr:nth-child(even) {
  background: #313239;
}

.table.device-list-table tr td {
  color: #bfc0c2;
  font-size: 14px;
}

.table.device-list-table tr th {
  background: #313239;
  padding: 16px 18px;
}

.table.device-list-table .device-id,
.table.device-list-table .action-icons {
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;
}

.table.device-list-table .action-icons a {
  color: #959599;
  display: inline-block;
  margin: 0 4px;
}

.table.device-list-table .action-icons a:hover {
  color: #ff8802;
}

.table.device-list-table tr th.device-id-th {
  padding-left: 68px;
}

.table.device-list-table tr th:last-child {
  padding-right: 26px;
}

/************************pagination-wrap********************************/
.pagination.device-paging {
  background: #313239;
  padding: 14px 0;
}

.pagination.device-paging li {
  background: #25262b;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin: 0 4px;
}

.pagination.device-paging li a {
  padding: 0;
  font-size: 16px;
  line-height: 28px;
  color: #bebebf;
}

.pagination.device-paging li.active,
.pagination.device-paging li:hover {
  background: #ff8802;
}

.pagination.device-paging li.active a,
.pagination.device-paging li:hover a {
  color: #fff;
}

.pagination.device-paging li .material-icons {
  font-size: 28px;
}

.download-file ul.file-format {
  display: flex;
}

.download-file ul.file-format li {
  border-right: #3c3e44 solid 1px;
}

.download-file ul.file-format li:last-child {
  border: none;
}

.download-file ul.file-format li a {
  color: #fff;
  font-size: 14px;
  padding: 0 16px;
  display: flex;
  align-items: center;
}

.export-excel.icon,
.export-pdf.icon {
  width: 25px;
  height: 30px;
  display: inline-block;
  margin-right: 8px;
  background-repeat: no-repeat;
  background-size: contain;
}

input[type="text"]:not(.browser-default) {
  font-size: 14px;
}

.export-excel.icon {
  background-image: url(../src/assets/export-excel.png);
}

.export-pdf.icon {
  background-image: url(../src/assets/pdf-download.png);
}

.block-title.detail h3:after {
  background: #ff8802;
}

.unique-box .title {
  color: #666666;
  font-size: 13px;
  margin-bottom: 5px;
  line-height: 15px;
  display: block;
}

.unique-box .device-info {
  color: #000000;
  font-size: 14px;
  line-height: 15px;
  font-weight: 500;
}

.link-style {
  color: #0062b0;
  text-decoration: underline;
  font-weight: 500;
}

.link-red-style {
  color: #ff0000;
  text-decoration: underline;
  font-weight: 500;
}

/*****not found*****/
.no-results.min-height {
  min-height: 460px;
}

.search-device.not-found {
  border: #cccccc solid 1px;
}

.no-results.d-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-results .not-found-text {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 310px;
  flex-direction: column;
}

.no-results .not-found-text img {
  margin-bottom: 25px;
}

.no-results .not-found-text h3 {
  font-size: 34px;
  font-weight: 700;
  margin-bottom: 10px;
}

.no-results .not-found-text p {
  color: #55565c;
}

/***************user report*****************/
.user-pic.report {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  margin: 0 15px 0 0;
}

.user-pic.report img {
  border-radius: 50%;
  width: 44px;
}

.hd-style-2 h5 {
  font-size: 16px;
  color: #000;
  margin-bottom: 10px;
  font-weight: 700;
}

.hd-style-2 .sub-title {
  color: #9e9e9e;
  font-size: 12px;
}

/******************tab design*********************/
.tab-style .tabs.parent,
.tabs.nested,
.tabs.detail-tab {
  background: none;
  overflow-y: visible;
  white-space: normal;
  height: auto;
}

.tab-style .tabs.parent li {
  width: 192px;
  height: auto;
  text-transform: capitalize;
  margin-right: 12px;
}

.tab-style .tabs.parent li a {
  padding: 15px 34px;
  line-height: 20px;
  font-size: 15px;
  color: #fff;
  border: #404147 solid 1px;
  border-radius: 10px;
  font-weight: 400;
}

.tab-style .tabs.parent .indicator,
.tabs.nested .indicator,
.detail-tab .indicator {
  display: none;
}

.tab-style .tabs.parent li a.active,
.tab-style .tabs.parent li a:hover {
  background: #ff8802;
}

.tabs .tab a:focus,
.tabs .tab a:focus.active {
  background: none;
}

.tabs.detail-tab li {
  text-transform: capitalize;
}

.tabs.nested .tab,
.tabs.detail-tab .tab {
  line-height: normal;
  height: auto;
}

.tabs.detail-tab {
  border-bottom: #44454b solid 1px;
  padding-bottom: 18px;
}

.tabs.detail-tab .tab a {
  color: #bfc0c2;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100px;
  padding: 0 23px;
  border: solid 1px transparent;
  transition: background 0.5s;
}

/*.tabs.detail-tab .tab a:hover, .tabs.detail-tab .tab a.active { border: #ff8802 solid 1px; border-radius: 8px; color: #ff8802; background-color: #212127; }
.tabs.detail-tab .tab a:hover label, .tabs.detail-tab .tab a.active label { color: #ff8802; }*/
/*.tabs.detail-tab .tab:first-child a{padding-left:0;}
*/

/***tab icon***/

.tabs-icon.television {
  background-image: url(../src/assets/television-new.png);
}

.tabs-icon.lighting {
  background-image: url(../src/assets/lighting-new.png);
}

.tabs-icon.smart-room-ac {
  background-image: url(../src/assets/smart-room-ac-new.png);
}

/***.tabs-icon.smart-fan { background-image: url(../src/assets/smart-fan-new.png); }***/
.tabs-icon.speaker {
  background-image: url(../src/assets/speaker-new.png);
}

.tabs-icon.room-camera {
  background-image: url(../src/assets/room-camera-new.png);
}

.tabs-icon.cctv {
  background-image: url(../src/assets/cctv-new.png);
}

/***tab icon-new***/
.tabs-icon.washing-maching {
  background-image: url(../src/assets/washing-maching-new.png);
}

.tabs-icon.smart-fan {
  background-image: url(../src/assets/smart-fan-new.png);
}

.tabs-icon.refrigerator {
  background-image: url(../src/assets/refrigerator-new.png);
}

.tabs-icon.smart-plug {
  background-image: url(../src/assets/smart-plug-new.png);
}

.tabs-icon.switch-device {
  background-image: url(../src/assets/switch-device.png);
}

.tabs-icon.door-phone {
  background-image: url(../src/assets/door-phone-new.png);
}

.tabs-icon.default-device {
  background-image: url(../src/assets/default_device-new.png);
}

/*tab icon in hover case*/
.tabs.detail-tab .tab a:hover .tabs-icon.television,
.tabs.detail-tab .tab a.active .tabs-icon.television {
  background-image: url(../src/assets/television-new-color.png);
}

.tabs.detail-tab .tab a:hover .tabs-icon.lighting,
.tabs.detail-tab .tab a.active .tabs-icon.lighting {
  background-image: url(../src/assets/lighting-new-color.png);
}

.tabs.detail-tab .tab a:hover .tabs-icon.smart-room-ac,
.tabs.detail-tab .tab a.active .tabs-icon.smart-room-ac {
  background-image: url(../src/assets/smart-room-ac-new-color.png);
}

/*.tabs.detail-tab .tab a:hover .tabs-icon.smart-fan, .tabs.detail-tab .tab a.active .tabs-icon.smart-fan { background-image: url(../src/assets/smart-fan-new-color.png); }*/
.tabs.detail-tab .tab a:hover .tabs-icon.speaker,
.tabs.detail-tab .tab a.active .tabs-icon.speaker {
  background-image: url(../src/assets/room-camera-new-color.png);
}

.tabs.detail-tab .tab a:hover .tabs-icon.room-camera,
.tabs.detail-tab .tab a.active .tabs-icon.room-camera {
  background-image: url(../src/assets/room-camera-new-color.png);
}

.tabs.detail-tab .tab a:hover .tabs-icon.cctv,
.tabs.detail-tab .tab a.active .tabs-icon.cctv {
  background-image: url(../src/assets/cctv-new-color.png);
}

/*tab icon new in hover case*/
.tabs.detail-tab .tab a:hover .tabs-icon.washing-maching,
.tabs.detail-tab .tab a.active .tabs-icon.washing-maching {
  background-image: url(../src/assets/washing-maching-new-hover.png);
}

.tabs.detail-tab .tab a:hover .tabs-icon.smart-fan,
.tabs.detail-tab .tab a.active .tabs-icon.smart-fan {
  background-image: url(../src/assets/smart-fan-new-hover.png);
}

.tabs.detail-tab .tab a:hover .tabs-icon.refrigerator,
.tabs.detail-tab .tab a.active .tabs-icon.refrigerator {
  background-image: url(../src/assets/refrigerator-new-hover.png);
}

.tabs.detail-tab .tab a:hover .tabs-icon.smart-plug,
.tabs.detail-tab .tab a.active .tabs-icon.smart-plug {
  background-image: url(../src/assets/smart-plug-new-hover.png);
}

.tabs.detail-tab .tab a:hover .tabs-icon.switch-device,
.tabs.detail-tab .tab a.active .tabs-icon.switch-device {
  background-image: url(../src/assets/switch-device-hover.png);
}

.tabs.detail-tab .tab a:hover .tabs-icon.door-phone,
.tabs.detail-tab .tab a.active .tabs-icon.door-phone {
  background-image: url(../src/assets/door-phone-new-hover.png);
}

.tabs.detail-tab .tab a:hover .tabs-icon.default-device,
.tabs.detail-tab .tab a.active .tabs-icon.default-device {
  background-image: url(../src/assets/default_device-new-hover.png);
}

/**watermark***/

.watermark {
  position: absolute;
  background-repeat: no-repeat;
  display: inline-block;
  background-image: url(../src/assets/black_logo.png);
  width: 143px;
  height: 62px;
  background-size: 100%;
  opacity: 0.2;
}

/****************watermark for product detail********************/
.watermark-logo {
  background-position: 100% 100%;
  background-repeat: no-repeat;
}

.watermark-logo.ac {
  background-image: url(../src/assets/ac-water-mark.jpg);
}

/* element css */

/*.py-20 { padding-top: 20px !important; padding-bottom: 20px !important; }
.px-30 { padding-left: 30px !important; padding-right: 30px !important; }*/
/*.mb-20 { margin-bottom: 20px !important; }*/
.mb-15 {
  margin-bottom: 15px !important;
}

.p-15 {
  padding: 15px;
}

.add-input.xl::placeholder {
  color: #999999;
}

.add-input.xl {
  height: 50px !important;
  line-height: 50px !important;
  font-size: 14px !important;
  box-sizing: border-box !important;
  background-color: transparent;
  border: 1px solid #ccc !important;
  border-radius: 3px !important;
  outline: none;
  width: 100%;
  color: #000;
  font-weight: 500;
  padding: 0 0 0 18px !important;
  box-shadow: none !important;
}

.add-input.xl:focus {
  border-color: #0062b0 !important;
}

.btn {
  letter-spacing: normal;
}

.add-input.md,
.btn.md {
  height: 40px !important;
  padding: 0 12px !important;
  line-height: 40px !important;
  font-size: 14px;
  font-weight: 500;
  box-sizing: border-box !important;
  text-transform: capitalize;
}
.add-input.notransform,
.btn.md {
  height: 40px !important;
  padding: 0 12px !important;
  line-height: 40px !important;
  font-size: 14px;
  font-weight: 500;
  box-sizing: border-box !important;
}
.add-btn.xl {
  height: 50px !important;
  line-height: 50px !important;
  font-size: 17px;
}

.logo-outer {
  margin-bottom: 25px;
}

.alert {
  padding: 0.8rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: var(--light-color);
  color: #333;
}

/* modify color start*/
.alert-danger {
  background: var(--danger-color);
  color: white;
  position: sticky;
  top: 5px;
  z-index: 2300;
}

.alert-success {
  background: var(--success-color);
  color: white;
  position: sticky;
  top: 5px;
  z-index: 2300;
}

/* modify color end */
.pad-Leftzero {
  padding-left: 0 !important;
}

.mTop20 {
  margin-top: 20px !important;
}

.mTop90 {
  margin-top: 90px !important;
}

.mtop10 {
  margin-top: 10px !important;
}

.mbtn {
  margin-bottom: 20px !important;
  margin-top: 30px;
}

.logo-wrapper {
  position: relative;
}

/*.logo-wrapper-inner { position: absolute; padding-top: 0; }
.ntxt { color: #ffffff !important; }

/**************upload css********************/
.form-upload .input-field .style-fix::placeholder {
  color: #b7b7b7;
}

.form-upload .input-field .style-fix {
  color: #fff;
}

input:webkit-autofill {
  background-color: none !important;
  background-image: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: none !important;
  background-color: none !important;
  background-image: none !important;
  box-shadow: 0 0 0px 1000px #fff inset !important;
  transition: background-color 5000s ease-inb-out 0s !important;
  border-bottom: 1px solid #9e9e9e !important;
  color: #000 !important;
}

input:-internal-autofill-selected {
  background-color: rgb(255, 255, 255) !important;
  background-image: none !important;
  color: -internal-light-dark-color(white, white);
}

.search-device input.add-input:-webkit-autofill,
.search-device input.add-input:-webkit-autofill:hover,
.search-device input.add-input:-webkit-autofill:focus,
.form-upload input:-webkit-autofill,
.form-upload input:-webkit-autofill:hover,
.form-upload input:-webkit-autofill:focus,
.form-upload input:-internal-autofill-selected,
.reset-form-field input input:-webkit-autofill,
.reset-form-field input input:-webkit-autofill:hover,
.reset-form-field input input:-webkit-autofill:focus {
  -webkit-text-fill-color: none !important;
  background-color: none !important;
  background-image: none !important;
  -webkit-box-shadow: 0 0 0px 1000px #2b2c33 inset !important;
  transition: background-color 5000s ease-inb-out 0s !important;
  color: #fff !important;
  margin-bottom: 0 !important;
  box-shadow: #fff 0 0 500px 0 inset !important;
}

.search-device input.add-input,
.search-device input[type="text"]:not(.browser-default) {
  margin-bottom: 0 !important;
}

.search-device .btn-dark {
  background: #2b2c33 !important;
}

.mtopBot10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.ntxt {
  color: #ffffff !important;
}

.d-inline {
  display: inline-block !important;
}

.pl-10 {
  padding-left: 10px;
}

.show {
  display: block;
}

.hidden {
  display: none;
}

.device-detail-wrap.device-model {
  margin-bottom: 40px;
}

.pt-10 {
  padding-top: 10px !important;
}

.btn.btn-style-2 {
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  box-shadow: none;
  text-transform: capitalize;
}

.btn.btn-style-2:hover {
  opacity: 1;
}

.btn.lg {
  height: 40px;
  line-height: 40px;
  padding: 0 32px;
  font-size: 16px;
  min-width: 195px;
}

.block-title.style-2 h3:after {
  display: none;
}

.device-detail-wrap.device-model .field-name {
  color: #fff;
  font-size: 18px;
}

.device-detail-wrap.device-model .form input::placeholder,
.control-capabilities input::placeholder {
  color: #626369;
}

.device-detail-wrap.device-model input[type="text"]:not(.browser-default) {
  border-bottom: 1px solid #45464c;
}

.device-detail-wrap.device-model input.valid[type="text"]:not(.browser-default),
.device-detail-wrap.device-model input[type="text"]:not(.browser-default):focus:not([readonly]) {
  box-shadow: 0 1px 0 0 #ff8802;
}

/*.device-detail-wrap.device-model .control-capabilities .field-name {
  margin-top: 10px;
}*/
.content-block.control-capabilities {
  overflow: visible;
}

.control-capabilities .dropdown-content li span {
  color: #626369;
}

.control-capabilities input {
  color: #626369;
}

.dropdown-content li>a,
.dropdown-content li>span {
  font-size: 14px;
}

.control-capabilities .dropdown-content.select-dropdown {
  background: #fff;
  padding: 10px 0;
  border: #fff solid 1px;
  border-radius: 6px;
  top: 42px !important;
}

.control-capabilities .dropdown-content.select-dropdown li {
  min-height: auto;
  line-height: 18px;
}

.control-capabilities .dropdown-content.select-dropdown li span {
  color: #000;
  background: #fff;
  padding: 8px 20px;
  display: block;
}

.control-capabilities .dropdown-content.select-dropdown li:hover span {
  color: #000;
  background: #ebf0fa;
}

.control-capabilities .select-dropdown.dropdown-content li.selected,
.control-capabilities .select-dropdown.dropdown-content li:hover {
  background-color: #2b2c33;
}

.control-capabilities .select-wrapper .caret {
  fill: #000000;
  right: -7px;
}

.control-capabilities .range h4 {
  font-size: 20px;
  margin-bottom: 15px;
}

.device-detail-wrap.device-model .range .field-name {
  font-size: 14px;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.theme-bg {
  background: #ff8802 !important;
}

.white-text {
  color: #fff !important;
}

.min-180 {
  min-width: 180px;
}

.form-upload input:-internal-autofill-selected {
  background-color: none !important;
  color: #fff !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.reset-form-field input:-internal-autofill-selected {
  background-color: rgb(43, 43, 51) !important;
  -webkit-text-fill-color: #fff !important;
}

.control-capabilities input {
  color: #fff;
}

.reset-form-field .input-field {
  min-height: 60px;
}

.control-capabilities .range h4 {
  font-size: 20px;
  margin-bottom: 32px;
  line-height: 30px;
}

.is-danger {
  color: red;
}

/*new css*/
input.add-input[type="text"]:not(.browser-default):focus:not([readonly]) {
  border-bottom: none;
  box-shadow: none;
}

.require,
.error-mes {
  color: #ff4a4a;
  position: absolute;
}

.require {
  left: 3px;
  top: -20px;
}

.error-mes {
  bottom: -13px;
  left: 12px;
}

/*popup screen*/
.modal-broad {
  width: 790px !important;
}

.modal-reset {
  background: #fff;
  border: none;
  border-radius: 8px;
  width: 680px;
}

.modal-reset .modal-close {
  color: #cacacc;
  padding: 0;
  right: 4px;
  top: 1px;
  position: absolute;
}

.modal-reset .modal-close:focus {
  background: none;
}

.modal-reset .popup-content {
  min-height: 250px;
}

.pb-30 {
  padding-bottom: 30px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.d-inline-flex {
  display: inline-flex;
}

.gray-text {
  color: #9e9e9e;
}

.modal-reset .block-bottom .title {
  color: #666;
}

.modal-reset .boxes .info:last-child {
  margin-bottom: 0;
}

.modal-reset .boxes a {
  color: #fff;
  text-decoration: underline;
}

.p-25 {
  padding: 25px !important;
}

.justify-content-center {
  justify-content: center;
}

.pr-20 {
  padding-right: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.px-25 {
  padding-left: 25px;
  padding-right: 25px;
}

.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.modal-reset .info-reset .title {
  margin-right: 6px;
}

.modal-reset .block-bottom .title,
.modal-reset .boxes .info {
  margin-bottom: 20px;
}

.block-bottom.popup-2 .boxes .title {
  margin-bottom: 8px;
}

.modal-reset .info.point:after {
  content: "";
  background: #808085;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 4px;
}

.block-bottom.popup-2 .info.point {
  padding-left: 20px;
}

.modal-reset .info.pointGreen:after {
  content: "";
  background: #008000;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 4px;
}

.block-bottom.popup-2 .info.pointGreen {
  padding-left: 20px;
}

.title.style-4 {
  text-transform: uppercase;
  position: relative;
}

.title.style-4:after {
  content: "";
  background: #404147;
  top: 9px;
  width: 104%;
  height: 1px;
  position: absolute;
  left: 0;
}

.title.style-4 span {
  background: #2b2c33;
  display: inline-block;
  padding-right: 8px;
  position: relative;
  z-index: 1;
}

.position-relative {
  position: relative;
}

.d-flex {
  display: flex;
}

.tootltip-box .title {
  display: inline-block;
}

.tootltip-box {
  display: none;
}

.tootltip-box-display {
  display: block;
}

.tootltip-box {
  width: 400px;
  right: 120px;
  top: 40px;
  padding: 30px 20px;
  border: #404147 solid 1px;
  background: #151619;
  border-radius: 10px;
  position: absolute;
  z-index: 2000;
}

.tootltip-box a.close {
  color: #cacacc;
  padding: 0;
  right: 15px;
  top: 15px;
  position: absolute;
}

.tootltip-box a.close i {
  font-size: 30px;
}

.pt-25 {
  padding-top: 25px;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.tootltip-box:before {
  width: 0;
  height: 0;
  content: "";
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 25px solid #404147;
  right: 48px;
  top: -25px;
  position: absolute;
}

.tootltip-box:after {
  width: 0;
  height: 0;
  content: "";
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-bottom: 24px solid #151619;
  right: 50px;
  top: -24px;
  position: absolute;
}

.height-reset {
  height: 194px;
  overflow-y: auto;
}

.p-0 {
  padding: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.height-reset::-webkit-scrollbar {
  display: none;
}

.height-reset {
  -ms-overflow-style: none;
}

.info.error {
  padding: 85px 0;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
}

.font-12 {
  font-size: 12px;
}

.pt-0 {
  padding-top: 0 !important;
}

.search-device .add-input::placeholder {
  font-size: 16px;
  color: #000000;
}

.copy-wrap {
  position: absolute;
  left: 0;
  bottom: 38px;
  padding: 0 12px;
}

.model-srch .select-wrapper input.select-dropdown:focus {
  border: #cccccc solid 1px;
}

.right-nav {
  margin-top: 14px;
  float: right;
  min-width: 280px;
}

.btn,
.btn-large,
.btn-small {
  background-color: #0062b0;
}

.font-13 {
  font-size: 13px;
}

.error-message {
  font-size: 14px;
  margin-top: 6px;
}

.font-300i {
  font-weight: 300;
  font-style: italic;
}

.footer.login {
  position: fixed;
  left: 0;
  bottom: 0;
  color: #dcdcdc;
  text-align: center;
  width: 100%;
  padding: 0 0 10px 0;
}

.logo-inr-wrap {
  width: 250px;
  background: #fff;
  height: 64px;
  display: flex;
  align-items: center;
  padding: 0 0 0 29px;
  float: left;
}

.d-none {
  display: none;
}

.bottom-shadow {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
}

.medium {
  font-weight: 500;
}

.tabs .tab {
  padding: 0;
}

.no-shadow,
.no-shadow:hover {
  box-shadow: none;
}

.tabs .tab {
  margin: 0 4px 4px 0;
}

.tabs .tab a,
.tabs .tab a:hover {
  height: 30px;
  background: #ebebeb;
  line-height: 30px;
  font-size: 12px;
  color: #333333;
  font-weight: 500;
  margin-right: 0px;
  border-radius: 20px;
  display: inline-block;
  padding: 0 19px;
  min-width: 123px;
  text-transform: uppercase;
}

.tabs .tab a:hover {
  background: #ff9f26;
  color: #fff;
}

.orange-bg {
  background: #ff9f26 !important;
}

.gray-bg {
  background: #e0e0e0 !important;
}

.dark-text {
  color: #333333 !important;
}

.search-device .btn {
  border-radius: 0 3px 3px 0;
}

.model-srch.reset {
  display: flex;
}

.model-srch.reset label.field-name {
  font-size: 14px;
  color: #858585;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 0 11px 0 0;
}

.model-srch.reset .select-dropdown.dropdown-trigger {
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  border: #cccccc solid 1px;
  border-radius: 3px;
  padding: 0 0 0 16px;
  margin: 0;
}

.page-title {
  min-height: 40px;
  line-height: 40px;
}

.search-device input.add-input,
input.add-input::placeholder,
.search-device input[type="text"]:not(.browser-default) {
  font-size: 14px !important;
  font-weight: 400;
}

.py-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}

.id-block .tabs .tab a:hover,
.id-block .tabs .tab a.active:hover {
  background: #ff9f26;
  color: #fff;
}

.id-block .tabs .tab a.active {
  background: #ebebeb;
  color: #333333;
}

.flex-end {
  justify-content: flex-end;
}

label.label {
  font-size: 12px;
  font-weight: 500;
  color: #666666;
  margin-bottom: 4px;
  display: block;
}

.value {
  font-size: 14px;
  font-weight: 500;
}

.tab-reset .tabs.nested {
  border-bottom: #d6d6d6 solid 1px;
  border-top: #d6d6d6 solid 1px;
  margin-top: 3px;
  padding: 5px 13px;
}

.tab-reset .tabs .tab {
  margin-bottom: 0;
  padding-right: 40px;
}

.tab-reset .tabs.reset-thumb .tab {
  padding-right: 4px;
}

.tab-reset .tabs .tab:last-child {
  padding-right: 0;
}

.tab-reset .tabs .tab a {
  color: #333333;
  font-weight: 500;
  height: auto;
  background: no-repeat;
  font-size: 14px;
  line-height: 20px;
  display: inline-table;
  padding: 5px 3px;
  text-transform: capitalize;
}

.tab-reset .tabs .tab a:hover,
.tab-reset .tabs .tab a.active {
  color: #ff9f26;
  text-decoration: underline;
  font-weight: 700;
}

.font-14 {
  font-size: 14px;
}

.gray-txt {
  color: #666666;
}

.right-nav a.logns,
.right-nav a.support {
  display: inline-flex;
  font-size: 12px;
  font-weight: 500;
  padding: 0 11px;
  border-radius: 4px;
  line-height: 36px;
}

.right-nav a.logns {
  background-color: #fff;
  color: #0062b0;
}

.right-nav a span {
  display: inline-block;
  padding: 0 9px;
}

.right-nav i.icn-logout,
.right-nav i.icn-support {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-position: center;
}

.right-nav i.icn-logout {
  background: url(../src/assets/logout-icn.png) no-repeat;
  width: 16px;
  height: 16px;
  margin-top: 9px;
}

.right-nav i.icn-support {
  background: url(../src/assets/support-icn.png) no-repeat;
  margin-top: 6px;
}

/**reset tab pics**/

.tabs.detail-tab.reset-thumb {
  overflow: visible;
  clear: both;
}

/*.tabs.detail-tab.reset-thumb.scroll-set{display: flex; overflow-x: auto;}*/
.tabs.detail-tab.reset-thumb.scroll-set::-webkit-scrollbar {
  display: none;
}

.detail-tab.reset-thumb .tabs-icon {
  width: 56px;
  height: 46px;
  display: flex;
  background-repeat: no-repeat;
  margin-bottom: 2px;
  background-position: center;
  transition: all 0.5s;
}

.detail-tab.reset-thumb {
  background: #f3f3f3;
  border-radius: 10px;
  border-bottom: none;
  padding: 0;
}

.detail-tab.reset-thumb .tab a {
  width: 140px;
  height: 110px;
  padding: 10px;
  display: inline-flex;
  text-decoration: none;
  overflow: visible;
  border-radius: 10px;
  margin: 0;
  position: relative;
}

.detail-tab.reset-thumb .tab a:hover,
.detail-tab.reset-thumb .tab a.active {
  color: #fff;
  background: #0062b0;
  text-decoration: none;
}

.detail-tab.reset-thumb .tab a .title {
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  font-weight: 400;
  word-break: break-all;
}

.detail-tab.reset-thumb .tab a:hover .title,
.detail-tab.reset-thumb .tab a.active .title {
  font-weight: 500;
}

.detail-tab.reset-thumb .tab a.active:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #0062b0;
  position: absolute;
  bottom: -10px;
}

/*reset capabilities*/
.reset-capabilities {
  background: #f3f3f3;
  border-radius: 6px;
}

.reset-capabilities .title {
  color: #ff9f26;
  font-size: 14px;
  font-weight: 500;
}

.reset-capabilities .separator-light {
  margin-bottom: 0;
  border: none;
}

.reset-capabilities .separator-light .row {
  margin-bottom: 7px;
}

.reset-capabilities .separator-light .device-info {
  font-size: 13px;
}

/*heading style 3*/
.btn:hover {
  opacity: 1;
}

input.update-inpt {
  height: 40px !important;
  border-bottom: #0062b0 solid 2px !important;
}

.block-title.style-3 {
  background: url(../src/assets/gray-style.jpg) repeat-x 0 11px;
}

.block-title.style-3 h3 {
  font-size: 16px;
  font-weight: 700;
  color: #000000;
  line-height: 20px;
  display: inline-block;
  background: #fff;
  padding: 0 12px 0 0;
  margin-bottom: 20px;
}

.block-title.style-4 h3 {
  font-size: 16px;
  font-weight: 700;
  color: #000000;
  line-height: 20px;
  display: inline-block;
  background: #fff;
  padding: 0 12px 0 0;
  margin-bottom: 7px;
}

.btn.btn-style-3 {
  border-radius: 20px;
  box-shadow: none;
  opacity: 1;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
}

.edit-link,
.edit-link:focus {
  background: no-repeat;
  border: none;
  cursor: pointer;
}

.edit-link i.icn {
  color: #0062b0;
  font-size: 16px;
  font-weight: 600;
}

.font-16 {
  font-size: 16px;
}

/*modal window reset*/
.popup-content.blue-modal {
  background: #ffffff;
  border-radius: 8px;
  padding: 0;
}

.block-title.style-2 h3 {
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 30px;
  color: #0062b0;
  font-weight: 700;
}

.popup-content.blue-modal .lite-bg {
  background: #f3f3f3;
  height: 32px;
}

.modal-reset .info-reset .title {
  margin-bottom: 0;
}

.popup-content.blue-modal .block-top {
  margin-bottom: 15px;
}

.popup-content.blue-modal .boxes .info {
  font-weight: 500;
  color: #000000;
  font-size: 14px;
}

.popup-content.blue-modal .title-section .title {
  font-size: 14px;
  font-weight: 500;
  color: #666666;
}

.modal-reset .modal-close i {
  color: #000;
  font-size: 24px;
}

.modal-reset .block-title.style-3 h3 {
  text-transform: uppercase;
}

.popup-content.blue-modal label.title {
  margin-bottom: 0;
  padding-right: 10px;
}

.popup-content.blue-modal .bottom-line {
  display: flex;
  color: #000;
}

.device-detail .separator-light {
  border-top: #cccccc solid 1px;
  padding-top: 7px;
}

.device-detail .separator-light:last-child {
  border-bottom: #cccccc solid 1px;
}

.table.style-4 tr th {
  padding: 13px 15px 13px 24px;
  font-size: 16px;
  color: #29414d;
  font-weight: 700;
}

.table.style-4 tr td {
  padding: 4px 15px 9px 24px;
  color: #333;
  font-size: 14px;
}

.table.style-4 tr {
  border-bottom: #dbdbdb solid 1px;
}

.table.style-4 tr:nth-child(even) {
  background: #f5f5f5;
}

.table.style-4 tr:nth-child(even) .lnkBtn {
  color: #333333;
}

/* .table.style-4 tr:nth-child(even) .reset-icon {
  color: #0062b0;
} */
.table.style-4 .reset-icon {
  color: #0062b0;
}

.table.style-4 button.download-icn {
  position: relative;
  bottom: -6px;
  left: 20%;
  color: #666;
}

.emailPopup {
  position: relative;
  min-height: 10px;
}

.emailPopup-set {
  position: absolute;
  max-width: 165px;
  right: 450px;
  top: -45px;
}

.is-danger.emailError {
  position: absolute;
  right: -114px;
  top: 4px;
}

.radius-6 {
  border-radius: 6px;
}

.noDeviceFound,
.error.content-reset {
  font-size: 20px;
  padding: 20px;
  line-height: 20px;
  color: #858585;
  font-weight: 500;
  justify-content: center;
  display: flex;
}

.radius-10 {
  border-radius: 10px;
}

.light-gray {
  background: #f3f3f3;
}

.no-device-icon {
  width: 56px;
  margin-right: 18px;
  height: 48px;
  display: inline-flex;
  background: url(../src/assets/no-device-found.png) no-repeat;
}

.error.content-reset .no-data-icon {
  width: 153px;
  margin-bottom: 10px;
  height: 96px;
  display: inline-flex;
  background: url(../src/assets/no-data.png) no-repeat;
}

.noDeviceFound .text-box,
.error.content-reset .text-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.alert.bgColor {
  background: #ec5758;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.error.content-reset {
  padding: 22px 0 70px 0;
}

.error.content-reset .text-box {
  /* width: 224px; */
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-weight: 500;
  color: #858585;
}

.email-sending-popup .d-flex.bottom {
  display: flex;
  justify-content: space-between;
  padding: 3px 20px 3px 20px;
}

.px-20 {
  padding-right: 20px;
  padding-left: 20px;
}

.gray.title-3 {
  font-size: 18px;
  line-height: 18px;
  color: #666;
  font-weight: 500;
  margin-bottom: 8px;
}

.unique-box.d-inline label.title,
.unique-box.d-inline .device-info {
  display: inline-block;
  padding-right: 5px;
}

.unique-box.d-inline.self {
  display: flex;
  align-self: center;
}

.email-sending-popup .cke_contents.cke_reset {
  height: 160px;
}

.email-sending-popup .gray-section {
  background: #f3f3f3;
  margin: 0 19px;
  margin-bottom: 15px;
  border-radius: 6px;
  padding-top: 6px;
}

.email-sending-popup .unique-box {
  margin-bottom: 5px;
}

.no-left-pad {
  padding-left: 0px !important;
}

.country-code-container {
  height: 40px !important;
  width: 40px !important;
  margin-right: 12px !important;
}

.country-code-container .form-control {
  border: none !important;
}

.country-code-container .flag-dropdown {
  background-color: #ffffff !important;
}

.country-code-search {
  margin-right: 10px !important;
}

.country-code-dropdown {
  padding-left: 10px !important;
}

.cke_contents.cke_reset {
  height: 160px !important;
}

.email_response_message {
  color: green;
  text-align: right;
  font-weight: bold;
}

.btn.btn-style-4,
.btn.btn-style-4:focus {
  color: #ff9f26;
  text-transform: capitalize;
  box-shadow: none;
  border-radius: 20px;
  border: #ff9f26 solid 1px;
  padding: 0 18px;
  line-height: 32px;
  height: 32px;
  background: none;
}

.bottom-right {
  right: 0;
  bottom: 0;
}

.top-right {
  right: 27px;
  top: 27px;
}

.btn.sm {
  line-height: 34px;
  height: 34px;
}

.min-140 {
  min-width: 140px;
}

.font-400 {
  font-weight: 400 !important;
}

.tabs.style-3 .tab a {
  padding: 3px 12px;
  min-width: 90px;
  background: #ebebeb;
  color: #000;
}

.tabs.style-3 .tab a:hover,
.tabs.style-3 .tab a.active {
  background: #ff9f26;
  text-decoration: none;
  color: #fff;
}

.px-15 {
  padding-right: 15px;
  padding-left: 15px;
}

.tabs.style-3 {
  border-bottom: #e6e6e6 solid 1px !important;
  text-align: left;
  padding-left: 0 !important;
  border-top: none !important;
  padding-bottom: 10px !important;
}

.listing-modal {
  border: #e6e6e6 solid 1px;
  border-radius: 8px;
  text-align: left;
}

.listing-modal .title-wrap {
  text-align: center;
  border-bottom: #e6e6e6 solid 1px;
  height: 36px;
  line-height: 36px;
  background: #f3f3f3;
  border-radius: 8px 8px 0 0;
}

.pb-15 {
  padding-bottom: 15px;
}

.expiry-box {
  background: #fce7e6;
  line-height: 22px;
  height: 22px;
  padding: 0 15px;
  border-radius: 8px;
  color: #ce423e;
  font-size: 11px;
}

.product-img {
  border: #e6e6e6 solid 1px;
  float: right;
  height: 75px;
  width: 85px;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-position: center;
}

.product-img.ac {
  background-image: url(../src/assets/product-ac.jpg);
}

.mb-10 {
  margin-bottom: 10px !important;
}

.product-img img {
  max-width: 100%;
}

.vertical-wrapper {
  height: 200px;
  overflow-y: auto;
}

.vertical-wrapper::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.vertical-wrapper {
  -ms-overflow-style: none;
}

.warrantySingle {
  height: 300px !important;
}

.product-detail {
  text-align: left;
}

.tab-icon-style {
  background: #f3f3f3;
  padding: 15px 25px !important;
  text-align: left;
  max-width: 736px;
  overflow-x: auto;
  height: auto;
  border-radius: 10px;
}

.tab-icon-style .tab {
  height: auto;
  margin: 0;
  max-width: 72px;
  margin-right: 25px;
}

.tab-icon-style .tab a,
.tab-icon-style .tab a.active,
.tab-icon-style .tab a:hover {
  background: #bfbfbf;
  float: left;
  width: 72px;
  height: 72px;
  border-radius: 100%;
  padding: 0;
  min-width: auto;
  line-height: 70px;
  border: #fff solid 2px;
  transition: all 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab-icon-style .tab a:focus,
.tab-icon-style .tab a.active:focus {
  background: #bfbfbf;
}

.tab-icon-style .tab a img {
  max-width: 100%;
  box-sizing: border-box;
  padding: 10px;
}

.tab-icon-style .tab a.active,
.tab-icon-style .tab a:hover {
  border: #ff9f26 solid 2px;
  box-shadow: 0 0 16px 0 rgba(242, 139, 21, 0.6);
}

.top-gray-border {
  border-top: #dbdbdb solid 1px;
}

.bottom-gray-border {
  border-bottom: #dbdbdb solid 1px;
}

.pl-50 {
  padding-left: 50px;
}

.position-relative {
  position: relative;
}

.value-list {
  font-size: 13px;
  color: #333333;
}

.mb-10 {
  margin-bottom: 10px;
}

.min-200 {
  min-height: 200px;
}

.logo-water-mark {
  background: url(../src/assets/logo-inner.png) no-repeat;
  width: 115px;
  height: 40px;
  position: absolute;
  left: 40%;
  top: 30%;
  background-size: 100%;
  opacity: 0.2;
}

.input_phone {
  height: 40px !important;
  line-height: 40px;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  border: #cccccc solid 1px !important;
  padding: 0 0 0 10px !important;
  margin-top: 10px !important;
  z-index: 99;
}

.vertical-block-tile {
  position: absolute;
  top: 5%;
  left: -110px;
  color: #cccccc;
  transform: rotate(270deg);
  transform-origin: 90% 40%;
}

.vertical-block-tile span {
  display: inline-block;
  color: #cccccc;
  min-width: 150px;
}

.px-70 {
  padding-right: 70px;
  padding-left: 70px;
}

.metrix-box {
  width: 200px;
  margin: 0 auto 8px auto;
}

.metrix-box img,
.metrics-box.large img {
  max-width: 100%;
}

.metric-image {
  height: 150px !important;
}

.mb-20 {
  margin-bottom: 20px;
}

.cursor-Hand {
  cursor: pointer;
}

.metrics-box.large {
  /* width: 560px;
  margin: 0 auto; */
  display: flex;
  justify-content: center;
}

.mb-24 {
  margin-bottom: 24px;
}

.medium-font {
  font-weight: 500;
}

.p-5 {
  padding: 5px;
}

.font-22 {
  font-size: 22px;
}

.table.style-4 .lnkBtn {
  color: #858585;
}

.justify-content-between {
  justify-content: space-between;
}

.wweek-box .name {
  padding: 5px 25px;
  background: #ebebeb;
  margin-right: 6px;
  border-radius: 26px;
  cursor: pointer;
}

.wweek-box .name.active {
  background: #0062b0;
  color: #fff;
}

.form-style-modal .input-field label.active {
  width: 100%;
  position: static;
  height: 15px;
  display: block;
  color: #3b3b3b;
  font-weight: 500;
  margin-bottom: 10px;
  transform: translate(0) scale(1);
  font-size: 14px;
}

.gray-text {
  color: #3b3b3b !important;
}

.form-style-modal .select-wrapper {
  position: relative;
  width: 90%;
}

.flex-end {
  justify-content: flex-end;
}

.form-style-modal input.add-input,
.form-style-modal input.add-input:focus,
.form-style-modal input.add-input[type="text"]:not(.browser-default):focus:not([readonly]) {
  border: #cccccc solid 1px;
  height: 40px;
  border-radius: 4px;
  padding: 0 16px;
  box-sizing: border-box;
}

.add-input.calendar {
  background: url(../src/assets/calendar-icon.png) no-repeat top 5px right 10px;
}

.control-capabilities.select-height .dropdown-content.select-dropdown {
  height: 150px;
  overflow-y: auto;
}

.react-datepicker-popper {
  z-index: 99;
}

.form-add-input {
  border: #cccccc solid 1px;
  height: 40px;
  border-radius: 4px;
  padding: 0 16px;
  box-sizing: border-box;
  margin: 2px;
}

.align-items-center {
  align-items: center;
}

.font-600 {
  font-weight: 600;
}

.justify-content-end {
  justify-content: flex-end;
}

.w-full {
  width: 100%;
}

.justify-space-between {
  justify-content: space-between;
}

.mb-25 {
  margin-bottom: 25px;
}

/* new css*/
.main-wrapper.round .top-bar {
  background: none;
}

.main-wrapper .set-space {
  padding: 0;
}

.main-wrapper.round .model-srch.reset .select-dropdown.dropdown-trigger {
  border-radius: 20px;
}

.mid-seprator {
  height: 3px;
  background: #797979;
  width: 450px;
  margin: 28px auto;
  border-radius: 5px;
}

.dropdown-content li>a,
.dropdown-content li>span {
  color: #333;
}

.new-layout-wraper .detail-tab.reset-thumb .tab {
  margin-bottom: 18px;
}

.select-wrapper input.select-dropdown:focus {
  border-bottom: 1px solid #939393;
}

.material-icons.tbl-srch {
  position: relative;
  top: 7px;
}

.roundOrRectangular .search-device {
  width: 100%;
}

.roundOrRectangular .select-wrapper input.select-dropdown {
  width: 220px;
}

.roundOrRectangular .control-capabilities .select-wrapper .caret {
  right: 0;
}

.roundOrRectangular .alert-reset {
  padding-left: 15px;
  padding-right: 15px;
}

.btnPosition {
  display: none;
}

.new-layout-wraper .btnPosition {
  display: block;
  position: absolute;
  right: 15px;
  top: 28px;
}

ul.pagination li.active,
ul.pagination li:hover {
  background-color: #ef6c00;
  color: #fff;
}

ul.pagination li:hover a {
  color: #fff;
}

.pt-25 {
  padding-top: 25px;
}

.select-status-box .select-wrapper {
  width: 150px;
}

.select-status-box input {
  width: 150px !important;
}

.ml-20 {
  margin-left: 20px;
}

.color-orange {
  color: #ff9f26 !important;
}

.color-gray {
  color: gray;
}

.ml_10 {
  margin-left: 10px;
}


.model-srch.full-w .select-wrapper {
  flex-grow: 1;
}

.model-srch.full-w .select-wrapper input.select-dropdown {
  width: 96% !important;
}

.model-srch.w-auto input.select-dropdown {
  width: auto !important;
}

.gray-text-light {
  color: #9e9e9e !important;
}

.px-5 {
  padding-right: 5px;
  padding-left: 5px
}

.min-h-250 {
  min-height: 250px;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.right {
  float: right;
  margin-right: 20px;
  color: gray;
}

.filter-value {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.filter-value div {
  width: 100px;
  cursor: pointer;
  padding: 5px 10px;
  text-align: center;
}

.filter-value .selectedFilter {
  background: black;
  border-radius: 15px;
  color: white;
}

.input_date {
  height: 40px !important;
  line-height: 40px;
  font-size: 14px;
  font-weight: 400;
  color: #000 !important;
  border: #cccccc solid 1px !important;
  padding: 0 0 0 10px !important;
  border-radius: 25px !important;
}

.device_main_div {
  display: flex;
  align-items: center;
}

.onboard-border {
  border-right: 2px solid lightgray;
  padding-right: 15px;
  margin-right: 15px;
}

.device_no {
  font-size: 20px;
  padding-top: 10px;
}

.date_button {
  width: 80px;
  height: 40px;
  border-radius: 0;
  color: white;
}

.filter_btn {
  border-radius: 25px;
}

.MuiAccordionDetails-root {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  flex-direction: column;
}

.over_float {
  position: absolute !important;
  z-index: 100 !important;
  width: 180px;
  right: 20%;
}

.over_float_second {
  position: absolute !important;
  z-index: 100 !important;
  width: 220px;
  right: 3%;
  max-height: 400px !important;
  overflow-y: scroll;

}

.MuiAccordionDetails-root {
  flex-wrap: nowrap !important;
}

.date_range_position {
  justify-content: flex-end;
}

.date_postion {
  position: absolute !important;
  right: 30%;
}

.float_left {
  float: left;
}

.float_right {
  float: right;
}

.mr-12 {
  margin-right: 12px;
}

.r-5 {
  right: 5%;
}

/* .chart_view {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.chart_view > div {
  width: 50%;
} */
.marker_detail {
  font-size: 25px;
  color: #abc4ff;
  position: absolute;
  display: block;
}

.marker_detail:hover {
  font-size: 40px;
  color: #abc4ff;
}

.detail_value {
  position: relative;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  bottom: 50px;
  right: 55px;
  width: 210px;
  background-color: #e6edfc;
  padding: 5px;
  height: 55px;
  /* white-space: nowrap; */
  /* border-radius: 10px; */
  clip-path: polygon(0 0, 0 80%, 25% 80%, 30% 100%, 32% 80%, 70% 80%, 70% 0);
  z-index: 2100;
}

.locationName {
  font-size: 18px;
  font-weight: 600;
}

.detail_location {
  font-size: 14px;
  margin: 3px 10px;
}

.gm-control-active.gm-fullscreen-control {
  display: none;
}

.pie_chart .apexcharts-canvas {
  margin: 0 15%;
}

.ml_10 {
  margin-left: 10px;
}

.w-150 {
  width: 150px;
  /* product metrics height */
}

.auto-height {
  height: 240px !important;

}

.m-10 {
  margin: 10px;
  height: 220px !important;
}

.mqtt_btn {
  padding: 15px 25px;
  font-size: 24px;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  border: none;
  border-radius: 15px;
  box-shadow: 0 9px !important;
}

.mqtt_btn:hover {
  background-color: #020402 !important
}

.mqtt_btn:active {
  background-color: #3e8e41;
  box-shadow: 0 5px !important;
  transform: translateY(4px);
}

.new_layout .over_float_second {
  right: 6%;
}

.test_class {
  position: relative;
  width: 30%;
  height: 30px;
}

.model_select {
  position: absolute !important;
  z-index: 100 !important;
  width: 275px;
}

.model_select label {
  color: gray !important;
}

.w-100 {
  width: 100px !important;
}

.date__picker .react-datepicker-wrapper {
  margin-right: 30px;
}

.justify_content_end {
  justify-content: end;
}

.apexcharts-yaxis-title-text {
  font-size: 16px !important;
  font-family: initial !important;
}

.title_flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title_border {
  border-right: 1px solid #9e9e9e;
  margin-right: 15px;
  padding-right: 15px;
}

.white_space {
  white-space: nowrap;
}

.profile-icon:focus {
  outline: none;
}

.profile-icon {
  color: white;
  box-shadow: none; 
}

.modal {
  display: block; 
  position: fixed; 
  z-index: 1; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0,0.4); 
  display: flex; 
  justify-content: center; 
  align-items: center; 
}


.modal-content {
  background-color: #fefefe;
  padding: 30px;
  border: 1px solid #888;
  width: 80%; 
  max-width: 600px; 
  border-radius: 10px; 
}

.close {
  color: #aaa;
  float: right;
  font-size: 30px; 
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-content h2 {
  text-align: center;
  margin-bottom: 20px; 
  color: #333; 
  font-size: 24px; 
}

.modal-content ol {
  margin-left: 30px; 
  padding-left: 0; 
  list-style-type: decimal; 
}

.modal-content li {
  margin-bottom: 15px; 
  line-height: 1.6; 
  color: #555; 
  font-size: 16px; 
}

.modal-content li strong {
  font-weight: bold; 
  color: #333; 
}

.modal-content p {
  margin-bottom: 15px; 
  line-height: 1.6; 
  color: #555; 
  font-size: 16px; 
}
